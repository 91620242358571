import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Client from "../../../../assets/pages/landingPage/WhyUs/Client.png";
import Expertise from "../../../../assets/pages/landingPage/WhyUs/Expertise.png";
import Innovation from "../../../../assets/pages/landingPage/WhyUs/Innovation.png";
import Reliability from "../../../../assets/pages/landingPage/WhyUs/Reliability.png";
import Transparency from "../../../../assets/pages/landingPage/WhyUs/Transparency.png";
import MClientale from "../../../../assets/pages/landingPage/WhyUs/MClientale.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

function MWhyUs() {
	return (
		<section id='mobile-whyus-section'>
			<Box
				sx={{
					backgroundColor: "#F9F9FA",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%" }}>
					<Box
						sx={{
							marginRight: "10%",
							marginLeft: "10%",
							paddingTop: "70px",
							marginBottom: "1%",
						}}
					>
						<AnimationOnScroll animateIn='animate__fadeInUpBig' delay={100}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Typography
									sx={{
										fontSize: "27px",
										fontWeight: 500,
										fontFamily: "Sqwared Medium",
									}}
								>
									Why Us
								</Typography>
							</Box>
						</AnimationOnScroll>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								marginTop: "5px",
							}}
						>
							<AnimationOnScroll animateIn='animate__fadeInUpBig' delay={200}>
								<Typography
									sx={{
										fontFamily: "Avenir",
										fontSize: "15px",
										marginTop: "3%",
										fontWeight: 500,
										lineHeight: "20.39px",
									}}
								>
									Our collaborative methodology ensures that your objectives are
									met on time and within budget, all while maintaining the
									highest quality standards.
								</Typography>
							</AnimationOnScroll>
						</Box>
						<AnimationOnScroll animateIn='animate__fadeInUpBig' delay={200}>
							<Box
								sx={{
									justifyContent: "center",
									display: "flex",
									marginTop: "10px",
									marginBottom: "30px",
								}}
							>
								<svg
									width='19'
									height='2'
									viewBox='0 0 19 2'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										opacity='0.5'
										d='M1 1H18'
										stroke='#686B77'
										stroke-linecap='square'
									/>
								</svg>
							</Box>
						</AnimationOnScroll>
						<AnimationOnScroll animateIn='animate__fadeInUpBig' delay={250}>
							<Box
								sx={{
									marginTop: "2%",
									"@media (min-width: 1600px)": {
										marginTop: "4%",
									},
								}}
							>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Box
											sx={{
												borderRadius: "9px",
												backgroundColor: "white",
												paddingLeft: "8%",
												paddingRight: "8%",
												paddingTop: "7%",
												paddingBottom: "7%",
												borderRadius: "9px 9px 9px 9px",
												border: "2px solid transparent",
												transition: "border-color 0.5s, opacity 0.5s",
												"&:hover": {
													border: "2px solid rgba(255, 192, 192, 1)",
													opacity: 0.8,
												},
											}}
										>
											<img
												src={Expertise}
												alt='Expertise'
												style={{ width: "30%" }}
											/>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontSize: "18px",
													marginTop: "4%",
													fontWeight: 500,
												}}
											>
												Expertise
											</Typography>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontSize: "15px",
													marginTop: "3%",
													fontWeight: 500,
													lineHeight: "20.39px",
												}}
											>
												Our team comprises skilled professionals with diverse
												backgrounds, ensuring a comprehensive understanding of
												various industries & project types.
											</Typography>
											<Box sx={{ marginTop: "2%" }}>
												<svg
													width='29'
													height='2'
													viewBox='0 0 29 2'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														opacity='0.5'
														d='M1 1H28'
														stroke='#686B77'
														stroke-width='0.8'
														stroke-linecap='square'
													/>
												</svg>
											</Box>
										</Box>
									</Grid>
									<Grid item xs={12}>
										<Box
											sx={{
												borderRadius: "9px",
												backgroundColor: "white",
												paddingLeft: "8%",
												paddingRight: "8%",
												paddingTop: "7%",
												paddingBottom: "7%",
												borderRadius: "9px 9px 9px 9px",
												border: "2px solid transparent",
												transition: "border-color 0.5s, opacity 0.5s",
												"&:hover": {
													border: "2px solid rgba(174, 227, 227, 1)",
													opacity: 0.8,
												},
											}}
										>
											<img
												src={Reliability}
												alt='Reliability'
												style={{ width: "30%" }}
											/>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontSize: "18px",
													marginTop: "4%",
													fontWeight: 500,
												}}
											>
												Reliability
											</Typography>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontSize: "15px",
													marginTop: "3%",
													fontWeight: 500,
													lineHeight: "20.39px",
												}}
											>
												When you choose Hertz and Henry, you're choosing a
												partner you can depend on. We deliver on our promises,
												consistently meeting and exceeding expectations.
											</Typography>
											<Box sx={{ marginTop: "2%" }}>
												<svg
													width='29'
													height='2'
													viewBox='0 0 29 2'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														opacity='0.5'
														d='M1 1H28'
														stroke='#686B77'
														stroke-width='0.8'
														stroke-linecap='square'
													/>
												</svg>
											</Box>
										</Box>
									</Grid>
									<Grid item xs={12}>
										<Box
											sx={{
												borderRadius: "9px",
												backgroundColor: "white",
												paddingLeft: "8%",
												paddingRight: "8%",
												paddingTop: "7%",
												paddingBottom: "7%",
												borderRadius: "9px 9px 9px 9px",
												border: "2px solid transparent",
												transition: "border-color 0.5s, opacity 0.5s",
												"&:hover": {
													border: "2px solid rgba(255, 220, 149, 1)",
													opacity: 0.8,
												},
											}}
										>
											<img
												src={Innovation}
												alt='Innovation'
												style={{ width: "30%" }}
											/>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontSize: "18px",
													marginTop: "4%",
													fontWeight: 500,
												}}
											>
												Innovation
											</Typography>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontSize: "15px",
													marginTop: "3%",
													fontWeight: 500,
													lineHeight: "20.39px",
												}}
											>
												We embrace the latest technologies and methodologies to
												keep your projects at the forefront of industry
												standards, fostering innovation and efficiency.
											</Typography>
											<Box sx={{ marginTop: "2%" }}>
												<svg
													width='29'
													height='2'
													viewBox='0 0 29 2'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														opacity='0.5'
														d='M1 1H28'
														stroke='#686B77'
														stroke-width='0.8'
														stroke-linecap='square'
													/>
												</svg>
											</Box>
										</Box>
									</Grid>
									<Grid item xs={12}>
										<Box
											sx={{
												borderRadius: "9px",
												backgroundColor: "white",
												paddingLeft: "8%",
												paddingRight: "8%",
												paddingTop: "7%",
												paddingBottom: "7%",
												height: "auto",
												border: "2px solid transparent",
												transition: "border-color 0.5s, opacity 0.5s",
												"&:hover": {
													border: "2px solid rgba(255, 193, 147, 1)",
													opacity: 0.8,
												},
											}}
										>
											<img
												src={Transparency}
												alt='Transparency'
												style={{ width: "30%" }}
											/>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontSize: "18px",
													marginTop: "4%",
													fontWeight: 500,
												}}
											>
												Transparency
											</Typography>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontSize: "15px",
													marginTop: "3%",
													fontWeight: 500,
													lineHeight: "20.39px",
												}}
											>
												Communication is key. We keep you informed at every
												step, providing clear insights into project progress,
												challenges, and solutions.
											</Typography>
											<Box sx={{ marginTop: "2%" }}>
												<svg
													width='29'
													height='2'
													viewBox='0 0 29 2'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														opacity='0.5'
														d='M1 1H28'
														stroke='#686B77'
														stroke-width='0.8'
														stroke-linecap='square'
													/>
												</svg>
											</Box>
										</Box>
									</Grid>
									<Grid item xs={12}>
										<Box
											sx={{
												borderRadius: "9px",
												backgroundColor: "white",
												paddingLeft: "8%",
												paddingRight: "8%",
												paddingTop: "7%",
												paddingBottom: "7%",
												borderRadius: "9px 9px 9px 9px",
												border: "2px solid transparent",
												transition: "border-color 0.5s, opacity 0.5s",
												"&:hover": {
													border: "2px solid rgba(151, 215, 255, 1)",
													opacity: 0.8,
												},
											}}
										>
											<img src={Client} alt='Client' style={{ width: "30%" }} />
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontSize: "18px",
													marginTop: "4%",
													fontWeight: 500,
												}}
											>
												Client - Centric
											</Typography>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontSize: "15px",
													marginTop: "3%",
													fontWeight: 500,
													lineHeight: "20.39px",
												}}
											>
												Your success is our priority. We work closely with you
												to tailor our services to your unique needs, providing
												personalised solutions that drive results.
											</Typography>
											<Box sx={{ marginTop: "2%" }}>
												<svg
													width='29'
													height='2'
													viewBox='0 0 29 2'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														opacity='0.5'
														d='M1 1H28'
														stroke='#686B77'
														stroke-width='0.8'
														stroke-linecap='square'
													/>
												</svg>
											</Box>
										</Box>
									</Grid>
								</Grid>
							</Box>
						</AnimationOnScroll>

						<AnimationOnScroll animateIn='animate__fadeInUpBig' delay={250}>
							<Box
								sx={{
									marginTop: "2%",
									display: "flex",
									justifyContent: "center",
									"@media (min-width: 1600px)": {
										marginTop: "4%",
									},
								}}
							>
								<Box
									sx={{
										width: "100%",
										border: "1px solid rgba(171, 180, 192, 0.3)",
										borderRadius: "9px 9px 9px 9px",
										padding: "1%",
										display: "flex",
										marginTop: "8%",
										alignItems: "center",
										justifyContent: "center",
										flexDirection: "column",
										"@media (min-width: 1600px)": {
											padding: "3%",
										},
									}}
								>
									<Typography
										sx={{
											fontSize: "18px",
											fontFamily: "Avenir",
											fontWeight: 400,
											textAlign: "center",
											marginTop: "3%",
										}}
									>
										BRANDS THAT TRUST US
									</Typography>
									<Box>
										<svg
											width='27'
											height='2'
											viewBox='0 0 27 2'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path d='M0 1H27' stroke='#22242F' stroke-width='0.8' />
										</svg>
									</Box>
									<Box
										sx={{
											width: "100%",
											marginTop: "10%",
											marginBottom: "10%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<img
											src={MClientale}
											alt='Clientale'
											style={{ width: "50%" }}
										/>
									</Box>
								</Box>
							</Box>
						</AnimationOnScroll>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default MWhyUs;
