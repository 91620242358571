import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import projectManagement from "../../../assets/pages/landingPage/services/PMS.png";
import powerSector from "../../../assets/pages/landingPage/services/PSP.png";
import petroChemical from "../../../assets/pages/landingPage/services/PP.png";
import lifeScience from "../../../assets/pages/landingPage/services/LSHC.png";
import OperationManagement from "../../../assets/pages/landingPage/services/OM.png";
import { SampleNextArrow } from "../../common/arrow";
import { SamplePrevArrow } from "../../common/arrow";
import Slider from "react-slick";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { Link } from "react-router-dom";

function Services() {
	const [hoverImage, setHoverImage] = useState(null);
	const [slideEnd, setSlideEnd] = useState(true);

	var settings = {
		infinite: false,
		speed: 500,
		arrows: true,
		slidesToShow: 4.5,
		slidesToScroll: 0.5,
		nextArrow: (
			<SampleNextArrow slideEnd={slideEnd} setSlideEnd={setSlideEnd} />
		),
		prevArrow: (
			<SamplePrevArrow slideEnd={slideEnd} setSlideEnd={setSlideEnd} />
		),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
					infinite: false,
					dots: false,
				},
			},
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
					infinite: false,
					dots: false,
				},
			},
			{
				breakpoint: 860,
				settings: {
					slidesToShow: 2.5,
					slidesToScroll: 2,
					infinite: false,
					dots: false,
				},
			},
		],
	};
	return (
		<section id='services-section' style={{marginTop: '5%', backgroundColor: '#F9F9FA'}}>
			<Box
				sx={{
					overflow: "hidden",
					backgroundColor: "#F9F9FA",
					"@media (min-width: 1441px)": {
						height: "100vh",
					},
					"@media (max-width: 830px)": {
						height: "auto",
					},
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%" }}>
					<Box
						sx={{
							marginRight: "3%",
							marginLeft: "3%",
							paddingTop: "70px",
							marginBottom: "1%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<AnimationOnScroll animateIn='animate__fadeInUpBig' delay={200}>
								<Typography
									sx={{
										fontSize: "40px",
										fontWeight: 500,
										fontFamily: "Sqwared Medium",
										textAlign: "left",
									}}
								>
									What we do? Services
								</Typography>
							</AnimationOnScroll>
							<AnimationOnScroll animateIn='animate__fadeInUpBig' delay={100}>
								<Box
									sx={{
										borderLeft: "2px solid #22242F",
										maxWidth: "60%",
										paddingLeft: "25px",
										marginTop: "12px",
									}}
								>
									<Typography
										sx={{
											textAlign: "left",
											fontFamily: "Avenir",
											fontSize: "18px",
											lineHeight: "30px",
											color: "#22242F",
										}}
									>
										At Hertz and Henry, we believe that successful project
										management is an art form. <br />
										Let us turn your vision into reality. Explore our services
										and discover how <br />
										we can elevate your projects to new heights. Welcome to a
										world <br />
										where precision meets passion – welcome to Hertz and Henry.
									</Typography>
								</Box>
							</AnimationOnScroll>
							<AnimationOnScroll animateIn='animate__fadeInUpBig' delay={100}>
								<Grid container spacing={1} sx={{ marginTop: "10px" }}>
									<Grid item xs={12} sm={6}>
										<Box sx={{ display: "flex" }}>
											<Box
												sx={{
													border:
														hoverImage === "projectManagement"
															? "2px solid transparent"
															: "2px solid rgba(162, 164, 175, 1)",
													borderRadius: "50%",
													width: "35px",
													height: "35px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													backgroundColor:
														hoverImage === "projectManagement"
															? "#E65663"
															: "transparent",
												}}
											>
												<Typography
													sx={{
														color:
															hoverImage === "projectManagement"
																? "white"
																: "rgba(162, 164, 175, 1)",
														fontSize: "12px",
														fontFamily: "Avenir",
													}}
												>
													{" "}
													1{" "}
												</Typography>
											</Box>
											<Box
												sx={{
													border:
														hoverImage === "powerSector"
															? "2px solid transparent"
															: "2px solid rgba(162, 164, 175, 1)",
													borderRadius: "50%",
													width: "35px",
													height: "35px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													marginLeft: "20px",
													backgroundColor:
														hoverImage === "powerSector"
															? "#E65663"
															: "transparent",
												}}
											>
												<Typography
													sx={{
														color:
															hoverImage === "powerSector"
																? "white"
																: "rgba(162, 164, 175, 1)",
														fontSize: "12px",
														fontFamily: "Avenir",
													}}
												>
													{" "}
													2{" "}
												</Typography>
											</Box>
											<Box
												sx={{
													border:
														hoverImage === "petroChemical"
															? "2px solid transparent"
															: "2px solid rgba(162, 164, 175, 1)",
													borderRadius: "50%",
													width: "35px",
													height: "35px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													marginLeft: "20px",
													backgroundColor:
														hoverImage === "petroChemical"
															? "#E65663"
															: "transparent",
												}}
											>
												<Typography
													sx={{
														color:
															hoverImage === "petroChemical"
																? "white"
																: "rgba(162, 164, 175, 1)",
														fontSize: "12px",
														fontFamily: "Avenir",
													}}
												>
													{" "}
													3{" "}
												</Typography>
											</Box>
											<Box
												sx={{
													border:
														hoverImage === "lifeScience"
															? "2px solid transparent"
															: "2px solid rgba(162, 164, 175, 1)",
													borderRadius: "50%",
													width: "35px",
													height: "35px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													marginLeft: "20px",
													backgroundColor:
														hoverImage === "lifeScience"
															? "#E65663"
															: "transparent",
												}}
											>
												<Typography
													sx={{
														color:
															hoverImage === "lifeScience"
																? "white"
																: "rgba(162, 164, 175, 1)",
														fontSize: "12px",
														fontFamily: "Avenir",
													}}
												>
													{" "}
													4{" "}
												</Typography>
											</Box>
											{/* <Box
												sx={{
													border:
														hoverImage === "operation"
															? "2px solid transparent"
															: "2px solid rgba(162, 164, 175, 1)",
													borderRadius: "50%",
													width: "35px",
													height: "35px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													marginLeft: "20px",
													backgroundColor:
														hoverImage === "operation"
															? "#E65663"
															: "transparent",
												}}
											>
												<Typography
													sx={{
														color:
															hoverImage === "operation"
																? "white"
																: "rgba(162, 164, 175, 1)",
														fontSize: "12px",
														fontFamily: "Avenir",
													}}
												>
													{" "}
													5{" "}
												</Typography>
											</Box> */}
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													marginLeft: "30px",
												}}
											>
												<svg
													width='84'
													height='2'
													viewBox='0 0 84 2'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														opacity='0.46'
														d='M0 1L84 1'
														stroke='#22242F'
														stroke-width='0.8'
													/>
												</svg>
											</Box>
										</Box>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												float: "right",
											}}
										>
											<a
												href='services'
												onClick={() =>
													localStorage.setItem("services", "productEngineering")
												}
											>
												<Box
													sx={{
														border: "1px solid rgba(162, 164, 175, 1)",
														padding: "2%",
														borderRadius: "30px",
														paddingLeft: "30px",
														paddingRight: "30px",
														marginLeft: "20px",
													}}
												>
													<Typography
														sx={{
															color: "#22242F",
															fontSize: "12px",
															fontFamily: "Avenir",
															letterSpacing: "5px",
														}}
													>
														CLICK SERVICES TO EXPLORE
													</Typography>
												</Box>
											</a>
										</Box>
									</Grid>
								</Grid>
							</AnimationOnScroll>
						</Box>
						<AnimationOnScroll animateIn='animate__fadeInUpBig' delay={100}>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								<Box
									style={{
										marginTop: "4%",
										width: "100%",
									}}
								>
									<Slider {...settings}>
										<a href='/services'>
											<Grid item xs={2}>
												<img
													src={projectManagement}
													alt='project'
													onMouseOver={() => setHoverImage("projectManagement")}
													onMouseOut={() => setHoverImage(null)}
													onClick={() =>
														localStorage.setItem(
															"services",
															"productEngineering"
														)
													}
													style={{
														height: "287px",
														width: "252px",
														objectFit: "cover",
														borderRadius: "10px",
														transition:
															"transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
													}}
												/>
											</Grid>
										</a>
										<a href='/services'>
											<Grid item xs={2}>
												<img
													src={powerSector}
													alt='powersector'
													onMouseOver={() => setHoverImage("powerSector")}
													onMouseOut={() => setHoverImage(null)}
													onClick={() =>
														localStorage.setItem(
															"services",
															"powerSectorProjects"
														)
													}
													style={{
														height: "287px",
														width: "252px",
														objectFit: "cover",
														borderRadius: "10px",
														transition:
															"transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
													}}
												/>
											</Grid>
										</a>
										<a href='/services'>
											<Grid item xs={2}>
												<img
													src={petroChemical}
													alt='petrochemical'
													onMouseOver={() => setHoverImage("petroChemical")}
													onMouseOut={() => setHoverImage(null)}
													onClick={() =>
														localStorage.setItem(
															"services",
															"petroChemicalProjects"
														)
													}
													style={{
														height: "287px",
														width: "252px",
														objectFit: "cover",
														borderRadius: "10px",
														transition:
															"transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
													}}
												/>
											</Grid>
										</a>
										<a href='/services'>
											<Grid item xs={2}>
												<img
													src={lifeScience}
													alt='lifescience'
													onMouseOver={() => setHoverImage("lifeScience")}
													onMouseOut={() => setHoverImage(null)}
													onClick={() =>
														localStorage.setItem("services", "lifeScience")
													}
													style={{
														height: "287px",
														width: "252px",
														objectFit: "cover",
														borderRadius: "10px",
														transition:
															"transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
													}}
												/>
											</Grid>
										</a>
										{/* <a href='/services'>
											<Grid item xs={2}>
												<img
													src={OperationManagement}
													onMouseOver={() => setHoverImage("operation")}
													onMouseOut={() => setHoverImage(null)}
													onClick={() =>
														localStorage.setItem(
															"services",
															"operationMaintanance"
														)
													}
													style={{
														opacity: slideEnd ? 0.3 : 1,
														height: "287px",
														width: "252px",
														objectFit: "contain",
														borderRadius: "10px",
														transform: slideEnd ? "Scale(0.92)" : "Scale(1)",
														transition:
															"transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
													}}
													alt=''
												/>
											</Grid>
										</a> */}
									</Slider>
								</Box>
							</Box>
						</AnimationOnScroll>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default Services;
