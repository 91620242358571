import Arrows from "../../assets/pages/landingPage/services/arrow.png";
export function SampleNextArrow(props) {
	const { className, style, onClick, setSlideEnd, slideEnd } = props;
	return (
		<img
			className={className}
			id='custom'
			alt='arrows'
			style={{
				...style,
				height: "40px",
				width: "100px",
				objectFit: "contain",
				zIndex: 9999,
				display: slideEnd ? "	" : "none",
			}}
			onClick={() => {
				if (typeof onClick === "function") {
					onClick();
				}
				setSlideEnd(false);
			}}
			src={Arrows}
		></img>
	);
}

export function SamplePrevArrow(props) {
	const { className, style, onClick, setSlideEnd, slideEnd } = props;
	return (
		<img
			className={className}
			id='custom'
			alt='arrows'
			style={{
				...style,
				height: "40px",
				display: slideEnd ? "none" : "block",
				width: "100px",
				objectFit: "contain",
				top: "130px",
				left: "-50px",
				transform: "rotate(180deg)",
				zIndex: 9999,
			}}
			onClick={() => {
				if (typeof onClick === "function") {
					onClick(); // Call onClick if it's a function
				}
				setSlideEnd(true); // Set slideEnd to false
			}}
			src={Arrows}
		></img>
	);
}
