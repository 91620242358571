export const JobsArrow = () => {
	return (
		<svg
			width='40'
			height='40'
			viewBox='0 0 50 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle cx='25' cy='25' r='24.3' stroke='#32363C' stroke-width='1.4' />
			<path
				d='M35 26C35.5523 26 36 25.5523 36 25C36 24.4477 35.5523 24 35 24V26ZM15.2929 24.2929C14.9024 24.6834 14.9024 25.3166 15.2929 25.7071L21.6569 32.0711C22.0474 32.4616 22.6805 32.4616 23.0711 32.0711C23.4616 31.6805 23.4616 31.0474 23.0711 30.6569L17.4142 25L23.0711 19.3431C23.4616 18.9526 23.4616 18.3195 23.0711 17.9289C22.6805 17.5384 22.0474 17.5384 21.6569 17.9289L15.2929 24.2929ZM35 24H16V26H35V24Z'
				fill='#32363C'
			/>
		</svg>
	);
};

export const ApplyNowArrow = () => {
	return (
		<svg
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle
				cx='14'
				cy='14'
				r='13.65'
				fill='#FCFCFC'
				stroke='#646879'
				stroke-width='0.7'
			/>
			<path
				d='M12.1367 9.33789L16.0963 13.2974C16.4868 13.688 16.4868 14.3211 16.0963 14.7117L12.1367 18.6712'
				stroke='#646879'
				stroke-linecap='round'
			/>
		</svg>
	);
};

export const ApplyNowArrowMob = () => {
	return (
		<svg
			width='18'
			height='18'
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle
				cx='14'
				cy='14'
				r='13.65'
				fill='#FCFCFC'
				stroke='#646879'
				stroke-width='0.7'
			/>
			<path
				d='M12.1367 9.33789L16.0963 13.2974C16.4868 13.688 16.4868 14.3211 16.0963 14.7117L12.1367 18.6712'
				stroke='#646879'
				stroke-linecap='round'
			/>
		</svg>
	);
};
