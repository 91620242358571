import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import AE1 from "../../../../assets/pages/landingPage/SubServices/AE1.png";
import AE2 from "../../../../assets/pages/landingPage/SubServices/AE2.png";
import AM1 from "../../../../assets/pages/landingPage/SubServices/AM1.png";
import AM2 from "../../../../assets/pages/landingPage/SubServices/AM2.png";
import CF1 from "../../../../assets/pages/landingPage/SubServices/CF1.png";
import CF2 from "../../../../assets/pages/landingPage/SubServices/CF2.png";
import CP1 from "../../../../assets/pages/landingPage/SubServices/CP1.png";
import CP2 from "../../../../assets/pages/landingPage/SubServices/CP2.png";
import DE1 from "../../../../assets/pages/landingPage/SubServices/DE1.png";
import DE2 from "../../../../assets/pages/landingPage/SubServices/DE2.png";
import DL1 from "../../../../assets/pages/landingPage/SubServices/DL1.png";
import DL2 from "../../../../assets/pages/landingPage/SubServices/DL2.png";
import EIA1 from "../../../../assets/pages/landingPage/SubServices/EIA1.png";
import EIA2 from "../../../../assets/pages/landingPage/SubServices/EIA2.png";
import EP1 from "../../../../assets/pages/landingPage/SubServices/EP1.png";
import EP2 from "../../../../assets/pages/landingPage/SubServices/EP2.png";
import LH1 from "../../../../assets/pages/landingPage/SubServices/LH1.png";
import LH2 from "../../../../assets/pages/landingPage/SubServices/LH2.png";
import LPG1 from "../../../../assets/pages/landingPage/SubServices/LPG1.png";
import LPG2 from "../../../../assets/pages/landingPage/SubServices/LPG2.png";
import OD1 from "../../../../assets/pages/landingPage/SubServices/OD1.png";
import OD2 from "../../../../assets/pages/landingPage/SubServices/OD2.png";
import OP1 from "../../../../assets/pages/landingPage/SubServices/OP1.png";
import OP2 from "../../../../assets/pages/landingPage/SubServices/OP2.png";
import OO1 from "../../../../assets/pages/landingPage/SubServices/OO1.png";
import OO2 from "../../../../assets/pages/landingPage/SubServices/OO2.png";
import PC1 from "../../../../assets/pages/landingPage/SubServices/PC1.png";
import PC2 from "../../../../assets/pages/landingPage/SubServices/PC2.png";
import PE1 from "../../../../assets/pages/landingPage/SubServices/PE1.png";
import PE2 from "../../../../assets/pages/landingPage/SubServices/PE2.png";
import PPP1 from "../../../../assets/pages/landingPage/SubServices/PPP1.png";
import PPP2 from "../../../../assets/pages/landingPage/SubServices/PPP2.png";
import PS1 from "../../../../assets/pages/landingPage/SubServices/PS1.png";
import PS2 from "../../../../assets/pages/landingPage/SubServices/PS2.png";
import PSS1 from "../../../../assets/pages/landingPage/SubServices/PSS1.png";
import PSS2 from "../../../../assets/pages/landingPage/SubServices/PSS2.png";
import RP1 from "../../../../assets/pages/landingPage/SubServices/RP1.png";
import RP2 from "../../../../assets/pages/landingPage/SubServices/RP2.png";
import SA1 from "../../../../assets/pages/landingPage/SubServices/SA1.png";
import SA2 from "../../../../assets/pages/landingPage/SubServices/SA2.png";
import SP1 from "../../../../assets/pages/landingPage/SubServices/SP1.png";
import SP2 from "../../../../assets/pages/landingPage/SubServices/SP2.png";
import TL1 from "../../../../assets/pages/landingPage/SubServices/TL1.png";
import TL2 from "../../../../assets/pages/landingPage/SubServices/TL2.png";
import TRL1 from "../../../../assets/pages/landingPage/SubServices/TRL1.png";
import TRL2 from "../../../../assets/pages/landingPage/SubServices/TRL2.png";

function TowardsRight({ title, number, image1, image2 }) {
  return (
    <Box sx={{ position: "relative", marginLeft: "1%", marginRight: "1%", marginTop:"5%" }}>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}></Grid>
					<Grid
						item
						xs={12}
						sm={6}
						sx={{
							border: "2px solid rgba(201, 201, 203, 1)",
							borderRadius: "10px 10px 10px 10px",
						}}
					>
						<Box
							sx={{ marginLeft: "25%", marginBottom: "7%", marginTop: "2%", height: '250px', display: "flex", justifyContent: "center", flexDirection: "column" }}
						>
							<Grid container spacing={1}>
								<Grid
									item
									xs={12}
									sm={6}
									sx={{
										display: "flex",
										justifyContent: "center",
										flexDirection: "column",
									}}
								>
									<Box
										sx={{
											paddingBottom: "5%",
										}}
									>
										<Typography
											sx={{
												fontFamily: "Avenir",
												fontWeight: 500,
												fontSize: "17px",
												textAlign: "left",
												color: "#202632",
											}}
										>
											{title}
										</Typography>
										<Box sx={{ marginTop: "5%", borderTop: "2px solid #202632", }}>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontWeight: 500,
													fontSize: "17px",
													textAlign: "left",
													color: "#202632",
													marginTop: "5%"
												}}
											>
												<a href='mailto:enquiry@hertzandhenry.com'>
								<Box
									sx={{
										display: "flex",
										marginTop: "2%",
										position: "relative",
										zIndex: "1000",
									}}
								>
									<Typography
										sx={{
											fontFamily: "Avenir",
											fontWeight: 500,
											fontSize: "16px",
											textAlign: "left",
											color: "#202632",
										}}
									>
										Need This Service
									</Typography>
									<svg
										style={{ marginLeft: "10px" }}
										width='22'
										height='22'
										viewBox='0 0 22 22'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<circle
											cx='11'
											cy='11'
											r='10.65'
											stroke='#161B26'
											stroke-width='0.7'
										/>
										<path
											d='M9.53516 7.33203L12.4947 10.2916C12.8852 10.6821 12.8852 11.3153 12.4947 11.7058L9.53516 14.6654'
											stroke='#161B26'
											stroke-linecap='round'
										/>
									</svg>
								</Box>
							</a>
											</Typography>
										</Box>
									</Box>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Typography
											sx={{
												fontFamily: "Avenir",
												fontWeight: 900,
												fontSize: { xs: "40px", sm: "60px", md: "100px" },
												textAlign: "right",
												color: "rgba(201, 201, 203, 1)",
												opacity: "33%",
												marginLeft: "10px",
											}}
										>
											{number}
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
				<Box
					sx={{
						position: "absolute",
						top: 12,
						left: 0,
						right: 0,
						bottom: 0,
						zIndex: 5,
					}}
				>
					<Grid container spacing={2} sx={{ height: "100%" }}>
						<Grid
							item
							xs={12}
							sm={4.2}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								height: "100%",
							}}
						>
							<Box sx={{ marginLeft: "5%" }}>
								<img src={image1} style={{ width: "100%" }} />
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sm={2.85}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								height: "100%",
							}}
						>
							<Box sx={{ marginLeft: "5%" }}>
								<img src={image2} style={{ width: "100%" }} />
							</Box>
						</Grid>
						<Grid item xs={12} sm={4.3}></Grid>
					</Grid>
				</Box>
			</Box>
  );
}

export default TowardsRight;
