import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ProcessLeft from "../../../assets/pages/landingPage/Process/Process.png";
import childBox from "../../../assets/pages/landingPage/About/childBox.png";
import camScan from "../../../assets/pages/landingPage/About/camScan.png";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";

function Process() {

  return (
    <section id="process-section" style={{marginTop: '5%'}}>
    <Box
      sx={{
        backgroundColor: "#F9F9FA",
        height: "100vh",
        "@media (min-width: 1441px)": {
          height: "100vh",
        },
        "@media (max-width: 830px)": {
          height: "auto",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ maxWidth: "1440px", width: "100%" }}>
        <Box>
          <Box
            sx={{
              marginRight: "5%",
              paddingTop: "70px",
              marginBottom: "1%",
              position: "relative",
              "@media (max-width: 810px)": {
                marginLeft: "2%",
              },
            }}
          >
            {/* Existing Grid */}
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                <AnimationOnScroll animateIn="animate__fadeInUpBig" delay={200}>
                  <Box
                    sx={{
                      backgroundImage: `url(${ProcessLeft})`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      height: "80vh",
                    }}
                  ></Box>
                  </AnimationOnScroll>
                </Grid>
                <Grid item xs={12} sm={6}>
                <AnimationOnScroll animateIn="animate__fadeInUpBig">
                  <Typography
                    sx={{
                      fontFamily: "Avenir",
                      fontWeight: 900,
                      fontSize: { xs: "40px", sm: "60px", md: "90px" },
                      textAlign: "right",
                      color: "rgba(201, 201, 203, 1)",
                      opacity: "33%",
                    }}
                  >
                    PROCESS
                  </Typography>
                  </AnimationOnScroll>
                </Grid>
              </Grid>
            </Box>

            {/* Additional Grid with z-index 1 */}
            <Box
              sx={{
                position: "absolute",
                top: "70px",
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4.8}></Grid>
                <Grid item xs={12} sm={6.2}>
                  <Box
                    sx={{
                      height: "80vh",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={11.5}>
                      <AnimationOnScroll animateIn="animate__fadeInUpBig" delay={200}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              paddingLeft: "12%",
                              backgroundRepeat: "no-repeat",
                              backgroundImage: `url(${childBox})`,
                              backgroundPosition: "left",
                              paddingTop: "13%",
                              paddingBottom: "13%",
                              paddingRight: "10%",
                              transition: "transform 0.3s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.05)",
                              },
                            }}
                          >
                            <Grid container>
                              <Grid item xs={12} sm={11}>
                                <Box>
                                  <Box
                                    sx={{ display: "flex", alignItems: "center" }}
                                  >
                                    <svg
                                      width="38"
                                      height="2"
                                      viewBox="0 0 38 2"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0 0.999025L38 0.999023"
                                        stroke="#F44946"
                                        stroke-width="1.6"
                                      />
                                    </svg>
                                    <Box sx={{ marginLeft: "3%" }}>
                                      <Typography
                                        sx={{
                                          color: "rgba(244, 73, 70, 1)",
                                          fontWeight: 500,
                                          fontSize: "18px",
                                          fontFamily: "Avenir",
                                        }}
                                      >
                                        OUR APPROACH
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box sx={{ marginTop: "5%", marginLeft: "3%" }}>
                                  
                                    <Typography
                                      sx={{
                                        color: "rgba(72, 75, 95, 1)",
                                        fontWeight: 500,
                                        fontSize: "18px",
                                        fontFamily: "Avenir",
                                      }}
                                    >
                                      We believe in a holistic approach to project
                                      management. Our approach is rooted in a commitment to pushing the boundaries of what's possible. Our
                                      collaborative methodology ensures that your
                                      objectives are met on time and within
                                      budget, all while maintaining the highest
                                      quality standards.
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={1}>
                                <Box
                                  sx={{
                                    backgroundRepeat: "no-repeat",
                                    backgroundImage: `url(${camScan})`,
                                    height: "100%",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }}
                                ></Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </AnimationOnScroll>
                      </Grid>
                      <Grid container xs={12} sm={0.5} alignItems="flex-end">
                      <AnimationOnScroll animateIn="animate__fadeInUpBig" delay={100}>
                        <Box sx={{ marginLeft: "50px", marginBottom: "25%" }}>
                          <svg
                            width="8"
                            height="125"
                            viewBox="0 0 8 125"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.99999 7.46666C5.91458 7.46666 7.46666 5.91459 7.46666 4C7.46666 2.08541 5.91458 0.533331 3.99999 0.533332C2.08541 0.533332 0.533328 2.08541 0.533328 4C0.533328 5.91459 2.08541 7.46666 3.99999 7.46666ZM4.65 125L4.65 119.098L3.35 119.098L3.35 125L4.65 125ZM4.65 119.098L4.64999 4L3.34999 4L3.35 119.098L4.65 119.098Z"
                              fill="#FF6E6B"
                            />
                          </svg>
                        </Box>
                        </AnimationOnScroll>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={0.5}></Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <svg
              width="237"
              height="8"
              viewBox="0 0 237 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 4C0 6.20914 1.79086 8 4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4ZM264 3.25H4V4.75H264V3.25Z"
                fill="#FF6E6B"
              />
            </svg>
          </Box>
      </Box>
    </Box>
    </section>
  );
}

export default Process;
