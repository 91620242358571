import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { JobsArrow } from "../../common/jobArrow";
import { JobCard } from "../../common/jobcard";
import axios from "axios"; 


export const Jobs = () => {

	const [jobData, setJobData] = useState([]);
	const [isFetched, setIsFetched] = useState(false);

	const [activeButton, setActiveButton] = useState("recent");

	const fetchAllJobs = async () => {
		try {
		  const apiUrl = `https://api.hertzandhenry.com/jobTable.php`;
		  const response = await axios.get(apiUrl);
		  
		  setJobData(response.data);

		  if(response.data.message !== "No records found"){
			setIsFetched(true);
		  }
		
		} catch (error) {
		  console.error("Error fetching job details:", error);
		}
	  };

	useEffect(() => {
		fetchAllJobs();
		
	  }, []); 

	// const handleButtonClick = (button) => {
	// 	setActiveButton(button);
	// 	switch (button) {
	// 		case "recent":
	// 			setJobData(jobData);
	// 			break;
	// 		case "applied":
	// 			setJobData(jobData.filter(job => job.status === "applied"));
	// 			break;
	// 		case "rejected":
	// 			setJobData(jobData.filter(job => job.status === "rejected"));
	// 			break;
	// 		default:
	// 			setJobData(jobData);
	// 	}
	// };

	return (
		<section id='servicesmaintab-section'>
			<Box
				sx={{
					overflow: "hidden",
					backgroundColor: "#FAFAFA",
					minHeight: "100vh",
					"@media (min-width: 1441px)": {
						minHeight: "100vh",
					},
					"@media (max-width: 830px)": {
						minHeight: "100vh",
					},
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Box
					sx={{
						maxWidth: "1440px",
						width: "100%",
						overflow: "hidden",
						maxHeight: "100vh",
					}}
				>
					<Box>
						<Box
							sx={{
								paddingTop: "120px",
								marginBottom: "1%",
								"@media (max-width: 810px)": {
									marginLeft: "2%",
								},
							}}
						>
							<div className='ml-[5%]'>
								<div className='w-full flex items-center mb-2'>
									<a href='/'>
										<i className='hidden xs:block sm:block'>
											<JobsArrow />
										</i>
									</a>
									<div className='flex justify-center w-[95%]'>
										<h1
											className='sm:text-[50px] xs:text-[30px]'
											style={{ fontFamily: "Sqwared Medium" }}
										>
											Job Vacancy
										</h1>
									</div>
								</div>
								<div className='flex flex-col items-center'>
									<div className='w-full flex justify-center mb-5'>
										<hr className='sm:w-12 w-8  h-0.5 bg-black'></hr>
									</div>

									<div className='flex sm:justify-center xs:justify-start mb-10 w-full whitespace-nowrap overflow-auto'>
										<div className='w-max py-1 bg-[#F2F2F2] flex text-white rounded-full'>
											<button
												className={`${
													activeButton === "recent" ? "bg-black" : "text-black"
												} px-5 py-1 rounded-full`}
												// onClick={() => handleButtonClick("recent")}
											>
												Jobs Posted Recently
											</button>
											{/* <button
												className={`${
													activeButton === "applied" ? "bg-black" : "text-black"
												} px-5 py-1 rounded-full`}
												onClick={() => handleButtonClick("applied")}
											>
												Applied
											</button>
											<button
												className={`${
													activeButton === "rejected"
														? "bg-black"
														: "text-black"
												} px-5 py-1 rounded-full `}
												onClick={() => handleButtonClick("rejected")}
											>
												Rejected
											</button> */}
										</div>
									</div>
									<div
										className='flex flex-col gap-4 sm:w-full md:w-[70%] pr-10 jobscroll pb-20'
										id='card-area'
										style={{
											maxHeight: "70vh",
											overflowY: "auto",
										}}
									>

									{isFetched === true ? (
									<JobCard data={jobData} />
									) : (
									<Box sx={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>No current openings...</Box>
									)}

									</div>
								</div>
							</div>
						</Box>
					</Box>
				</Box>
			</Box>
		</section>
	);
};
