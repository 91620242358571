import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CF1 from "../../../../../assets/pages/landingPage/SubServices/CF1.png";
import DL1 from "../../../../../assets/pages/landingPage/SubServices/DL1.png";
import PPP1 from "../../../../../assets/pages/landingPage/SubServices/PPP1.png";
import SP1 from "../../../../../assets/pages/landingPage/SubServices/SP1.png";
import TL1 from "../../../../../assets/pages/landingPage/SubServices/TL1.png";

const servicesData = [
	{
		image: PPP1,
		title: "Power Plants\nProjects",
		description:
			"",
	},
	{
		image: SP1,
		title: "Substations Projects",
		description:
			"",
	},
	{
		image: TL1,
		title: "Transmission Lines",
		description:
			"",
	},
	{
		image: DL1,
		title: "Distribution Lines",
		description:
			"",
	},
	{
		image: CF1,
		title: "Consumer Facilities",
		description:
			"",
	},
];

function PowerSector() {
	return (
		<Box sx={{ marginTop: "2%" }}>
			<Box sx={{ position: "relative", marginLeft: "1%", marginRight: "1%" }}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						{servicesData.map((service, index) => (
							<Box key={index} sx={{ marginBottom: "7%", marginTop: "2%" }}>
								<Grid container spacing={1}>
									<Grid
										item
										xs={12}
										sx={{
											display: "flex",
											justifyContent: "center",
											flexDirection: "column",
										}}
									>
										<Grid item xs={12} style={{ marginBottom: "20px" }}>
											<img
												src={service.image}
												alt='engineering'
												style={{ width: "100%" }}
											/>
										</Grid>
										<Box
											sx={{
												borderBottom: "2px solid #202632",
												paddingBottom: "2%",
												width: "40%",
											}}
										>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontWeight: 500,
													fontSize: "17px",
													textAlign: "left",
													whiteSpace: "nowrap",
													color: "#202632",
												}}
											>
												{service.title.split("\n").map((line, index) => (
													<React.Fragment key={index}>
														{line}
														{index !== service.title.split("\n").length - 1 && (
															<br />
														)}
													</React.Fragment>
												))}
											</Typography>
										</Box>
										{/* <Box sx={{ marginTop: "3%" }}>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontWeight: 500,
													fontSize: "17px",
													textAlign: "left",
													color: "#202632",
													marginBottom: "20px",
												}}
											>
												Read more below
											</Typography>
										</Box> */}
									</Grid>
									<Grid item xs={12} sm={4}>
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<Typography
												sx={{
													position: "absolute",
													fontFamily: "Avenir",
													right: 0,
													marginTop: "-50px",
													fontWeight: 900,
													fontSize: { xs: "100px", sm: "100px", md: "100px" },
													textAlign: "right",
													color: "rgba(201, 201, 203, 1)",
													opacity: "33%",
													marginLeft: "10px",
												}}
											>
												{index + 1 < 10 ? `0${index + 1}` : index + 1}
											</Typography>
										</Box>
									</Grid>
								</Grid>

								<Box sx={{ width: "80%", marginTop: "-3%" }}>
									<Typography
										sx={{
											fontFamily: "Avenir",
											fontWeight: 500,
											fontSize: "16px",
											textAlign: "left",
											color: "#202632",
										}}
									>
										{service.description}
									</Typography>
								</Box>
								<a href='mailto:enquiry@hertzandhenry.com'>
									<Box
										sx={{
											display: "flex",
											marginTop: "2%",
											position: "relative",
											zIndex: "1000",
										}}
									>
										<Typography
											sx={{
												fontFamily: "Avenir",
												fontWeight: 500,
												fontSize: "16px",
												textAlign: "left",
												color: "#202632",
											}}
										>
											Need This Service
										</Typography>
										<svg
											style={{ marginLeft: "10px" }}
											width='22'
											height='22'
											viewBox='0 0 22 22'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<circle
												cx='11'
												cy='11'
												r='10.65'
												stroke='#161B26'
												strokeWidth='0.7'
											/>
											<path
												d='M9.53516 7.33203L12.4947 10.2916C12.8852 10.6821 12.8852 11.3153 12.4947 11.7058L9.53516 14.6654'
												stroke='#161B26'
												strokeLinecap='round'
											/>
										</svg>
									</Box>
								</a>
							</Box>
						))}
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default PowerSector;
