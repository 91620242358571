import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Client from "../../../../assets/pages/landingPage/WhyUs/Client.png";
import Expertise from "../../../../assets/pages/landingPage/WhyUs/Expertise.png";
import Innovation from "../../../../assets/pages/landingPage/WhyUs/Innovation.png";
import Reliability from "../../../../assets/pages/landingPage/WhyUs/Reliability.png";
import Transparency from "../../../../assets/pages/landingPage/WhyUs/Transparency.png";
import MClientale from "../../../../assets/pages/landingPage/WhyUs/MClientale.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function MFooter() {
	const scrollToSection = (sectionId) => {
		if (
			window.location.pathname === "/services" ||
			window.location.pathname === "/jobs"
		) {
			window.location = "/";
		}
		const section = document.getElementById(sectionId);

		if (section) {
			section.scrollIntoView({
				behavior: "smooth",
			});
		}
	};

	return (
		<section id='mobile-footer-section'>
			<Box
				sx={{
					backgroundColor: "#F9F9FA",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%" }}>
					<Box
						sx={{
							marginRight: "10%",
							marginLeft: "10%",
							paddingTop: "70px",
							marginBottom: "5%",
						}}
					>
						<AnimationOnScroll animateIn='animate__fadeInUpBig' delay={100}>
							<Accordion
								elevation={0}
								sx={{
									backgroundColor: "#FAFAFA",
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls='panel1a-content'
									id='panel1a-header'
								>
									<Typography
										sx={{
											fontSize: "18px",
											fontFamily: "Avenir",
											color: "#434343",
										}}
									>
										Explore
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography
										sx={{
											fontSize: "18px",
											fontFamily: "Avenir",
											color: "#434343",
										}}
									>
										<a
											href='/services'
											onClick={() =>
												localStorage.setItem("services", "productEngineering")
											}
										>
											{" "}
											Services <br />
										</a>
										Portfolio
									</Typography>
								</AccordionDetails>
							</Accordion>

							<Accordion
								elevation={0}
								sx={{
									backgroundColor: "#FAFAFA",
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls='panel1a-content'
									id='panel1a-header'
								>
									<Typography
										sx={{
											fontSize: "18px",
											fontFamily: "Avenir",
											color: "#434343",
										}}
									>
										Information
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography
										sx={{
											fontSize: "18px",
											fontFamily: "Avenir",
											color: "#434343",
										}}
									>
										<a
											href='javascript:;'
											onClick={() => {
												scrollToSection("mobile-about-section");
											}}
										>
											About us <br />
										</a>
										Blog <br />
									</Typography>
								</AccordionDetails>
							</Accordion>

							<Accordion
								elevation={0}
								sx={{
									backgroundColor: "#FAFAFA",
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls='panel1a-content'
									id='panel1a-header'
								>
									<Typography
										sx={{
											fontSize: "18px",
											fontFamily: "Avenir",
											color: "#434343",
										}}
									>
										Others
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography
										sx={{
											fontSize: "18px",
											fontFamily: "Avenir",
											color: "#434343",
										}}
									>
										<a
											href='https://admin.hertzandhenry.com/login'
											target='blank'
										>
											Login <br />
										</a>
										<a href='/jobs'>
											Job Vacancy <br />
										</a>
										<a href='mailto:enquiry@hertzandhenry.com'>Enquire Us</a>
									</Typography>
								</AccordionDetails>
							</Accordion>

							<Accordion
								elevation={0}
								sx={{
									backgroundColor: "#FAFAFA",
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls='panel1a-content'
									id='panel1a-header'
								>
									<Typography
										sx={{
											fontSize: "18px",
											fontFamily: "Avenir",
											color: "#434343",
										}}
									>
										Head Office Contact
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography
										sx={{
											fontSize: "14px",
											fontFamily: "Avenir",
											color: "#434343",
										}}
									>
										<span style={{whiteSpace:"nowrap"}}>Hertz and Henry Engineers India Pvt. Ltd.</span><br/>
										Building No: CC53/
										3080, First Floor, South Bridge Avenue Road, Panampilly
										Nagar, Kochi 682036, Kerala, India
										<br /> Office :  00 91 484-4868754 <br />
										<br /> Mobile : +91 9747143932 <br />
										Get connected with us
									</Typography>
								</AccordionDetails>
							</Accordion>
							<Box
								sx={{
									marginTop: "10%",
									display: "flex",
									justifyContent: "center",
								}}
							>
								<svg
									style={{ marginRight: "20px" }}
									width='30'
									height='31'
									viewBox='0 0 30 31'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<circle
										cx='14.8828'
										cy='15.7734'
										r='14.5828'
										stroke='#262F40'
										stroke-width='0.6'
									/>
									<rect
										x='9.31641'
										y='9.94922'
										width='12.0023'
										height='12.2341'
										rx='2'
										fill='#262F40'
									/>
									<path
										d='M17.4463 16.0629C17.4463 17.2739 16.4857 18.2391 15.3208 18.2391C14.1559 18.2391 13.1953 17.2739 13.1953 16.0629C13.1953 14.852 14.1559 13.8867 15.3208 13.8867C16.4857 13.8867 17.4463 14.852 17.4463 16.0629Z'
										stroke='#FAFAFA'
									/>
									<path
										d='M18.7211 12.8839C18.7211 13.0473 18.5921 13.1733 18.441 13.1733C18.2899 13.1733 18.1609 13.0473 18.1609 12.8839C18.1609 12.7205 18.2899 12.5945 18.441 12.5945C18.5921 12.5945 18.7211 12.7205 18.7211 12.8839Z'
										stroke='#FAFAFA'
										stroke-width='0.4'
									/>
								</svg>

								<svg
									style={{ marginRight: "20px" }}
									width='31'
									height='31'
									viewBox='0 0 31 31'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<circle
										cx='15.5'
										cy='15.7734'
										r='14.5828'
										stroke='#262F40'
										stroke-width='0.6'
									/>
									<path
										d='M17.7046 16.3008H16.0932V21.1133H13.9448V16.3008H12.1831V14.3242H13.9448V12.7988C13.9448 11.0801 14.9761 10.1133 16.5444 10.1133C17.2964 10.1133 18.0913 10.2637 18.0913 10.2637V11.9609H17.2104C16.3511 11.9609 16.0932 12.4766 16.0932 13.0352V14.3242H18.0053L17.7046 16.3008Z'
										fill='#262F40'
									/>
								</svg>

								<svg
									width='30'
									height='31'
									viewBox='0 0 30 31'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<circle
										cx='15.1172'
										cy='15.7734'
										r='14.5828'
										stroke='#262F40'
										stroke-width='0.6'
									/>
									<path
										d='M20.6847 13.6172C20.6847 13.7441 20.6847 13.8457 20.6847 13.9727C20.6847 17.502 18.0187 21.5391 13.1183 21.5391C11.5948 21.5391 10.1984 21.1074 9.03039 20.3457C9.23351 20.3711 9.43664 20.3965 9.66515 20.3965C10.9093 20.3965 12.0519 19.9648 12.9659 19.2539C11.798 19.2285 10.8077 18.4668 10.4777 17.4004C10.6554 17.4258 10.8077 17.4512 10.9855 17.4512C11.214 17.4512 11.4679 17.4004 11.671 17.3496C10.4523 17.0957 9.5382 16.0293 9.5382 14.7344V14.709C9.89367 14.9121 10.3253 15.0137 10.757 15.0391C10.0206 14.5566 9.56359 13.7441 9.56359 12.8301C9.56359 12.3223 9.69054 11.8652 9.91906 11.4844C11.2394 13.084 13.2198 14.1504 15.4288 14.2773C15.378 14.0742 15.3527 13.8711 15.3527 13.668C15.3527 12.1953 16.546 11.002 18.0187 11.002C18.7804 11.002 19.4659 11.3066 19.9737 11.8398C20.5577 11.7129 21.1417 11.4844 21.6495 11.1797C21.4464 11.8145 21.0402 12.3223 20.4816 12.6523C21.0148 12.6016 21.548 12.4492 22.005 12.2461C21.6495 12.7793 21.1925 13.2363 20.6847 13.6172Z'
										fill='#262F40'
									/>
								</svg>
							</Box>

							<Box
								sx={{
									marginTop: "10%",
									display: "flex",
									justifyContent: "center",
								}}
							>
								<Typography
									sx={{
										fontSize: "18px",
										fontFamily: "Avenir",
										color: "#434343",
									}}
								>
									© 2023 | All rights reserved
								</Typography>
							</Box>
						</AnimationOnScroll>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default MFooter;
