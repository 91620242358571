export default function CircleMenu() {
	return (
		<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="14" cy="14" r="14" fill="#090C12"/>
<circle cx="14" cy="14" r="9.7" stroke="#EEEEEE" stroke-width="0.6" stroke-dasharray="4 4"/>
</svg>


	);
}
