import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Box,
  Divider,
  Typography,
  Snackbar,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Upload from "./image.svg";
import Limit from "./limit.svg";

const Job = ({ open, onClose, jobId }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [experience, setExperience] = useState("");
  const [relocationOption, setRelocationOption] = useState("");

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleExperiencChange = (event) => {
    setExperience(event.target.value);
  };

  const handleRelocationChange = (event) => {
    setRelocationOption(event.target.value);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleSelectedFile = (files) => {
    const fileArray = Array.from(files);
    console.log(fileArray);
    const fileNames = fileArray.map((file) => file.name);
    console.log(fileNames);

    setSelectedFiles(fileArray);
  };

  // const handleApplyJob = async () => {
  //   try {
  //     const requestData = {
  //       firstName: firstName,
  //       lastName: lastName,
  //       email: email,
  //       phone_no: phone,
  //       experience: experience,
  //       city: city,
  //       resume: imageBlobUrl,
  //       status: "pending",
  //       relocate: relocationOption,
  //     };
  //     console.log(requestData)

  //     const response = await axios.post(
  //       "https://api.hertzandhenry.com/careerTable.php",
  //       requestData
  //     );
  //       console.log(response.data)
  //     showSnackbar("success", "Applied sucessfuly!");

  //     setTimeout(() => {
  //       setSnackbarOpen(false);
  //       onClose();
  //     }, 3000);
  //   } catch (error) {

  //     showSnackbar("error", "Error posting job. Please try again.");
  //   }
  // };

  const [imageBlobUrl, setImageBlobUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleApplyJob = async () => {
    if (!validateForm()) {
      return;
    }
    const file = selectedFiles[0];
  
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const uploadResponse = await fetch(
        "https://hnhd.hertzandhenry.com/upload_image.php",
        {
          method: "POST",
          body: formData,
        }
      );
  
      const data = await uploadResponse.json();
      const serverFilePath = data.filePath;
  
      console.log(data);
      setImageBlobUrl(`https://hnhd.hertzandhenry.com/${serverFilePath}`);
  
      const careerURL = "https://api.hertzandhenry.com/careerTable.php";
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      console.log(jobId);
  
      const JobID = parseInt(jobId, 10);
  
      const requestData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone_no: parseInt(phone, 10),
        experience: experience,
        city: city,
        job_id: JobID,
        resume: `https://hnhd.hertzandhenry.com/${serverFilePath}`,
        status: "pending",
        relocate: relocationOption,
      };
  
      console.log(requestData);
  
      const response = await axios.post(careerURL, requestData, axiosConfig);
      console.log(response.data);
      showSnackbar("success", "Applied successfully!");
  
      setTimeout(() => {
        setSnackbarOpen(false);
        onClose(); 
        clearFields(); 
      }, 3000);
    } catch (error) {
      showSnackbar("error", "Error sending invoice. Please try again.");
    }
  };
  
  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setCity("");
    setExperience("");
    setRelocationOption("");
    setSelectedFiles([]);
    setImageBlobUrl(null);
  };

  const showSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const fileInputRef = useRef(null);

  const handleOpenClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    experience: "",
    relocationOption: "",
    resume: "",
  });

  const validateForm = () => {
    const newErrors = {
      firstName: firstName.trim() === "" ? "First name is required" : "",
      lastName: lastName.trim() === "" ? "Last name is required" : "",
      email: email.trim() === "" ? "Email is required" : "",
      phone: phone.trim() === "" ? "Phone is required" : "",
      city: city.trim() === "" ? "City, Country is required" : "",
      experience: experience.trim() === "" ? "Experience is required" : "",
      relocationOption:
        relocationOption.trim() === "" ? "Relocation option is required" : "",
      resume: selectedFiles.length === 0 ? "Resume is required" : "",
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error !== "");
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "15px", maxWidth: "400px" } }}
    >
      <DialogContent sx={{ mb: 2 }}>
        <Box display="flex" mb={2} justifyContent={"center"}>
          <Typography sx={{ fontFamily: "Avenir", fontWeight: 600 }}>
            Application form
          </Typography>
        </Box>

        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            marginBottom: "15px",
          }}
        ></div>

        <Box>
          <Typography
            sx={{ fontFamily: "Avenir", fontSize: "14px", paddingLeft: "15px" }}
          >
            First name
          </Typography>
          <TextField
            value={firstName}
            onChange={handleFirstNameChange}
            fullWidth
            // helperText={errors.firstName}
            // error={Boolean(errors.firstName)}
            InputProps={{
              style: {
                paddingRight: "30px",
                height: "40px",
                marginTop: 5,
                borderRadius: 5,
              },
              IconComponent: () => null,
            }}
          />
          {errors.firstName && (
          <Typography
          sx={{
            fontSize: "13px",
            color: "#de6161",
            fontFamily: "Avenir", 
            marginBottom: 2,
            marginLeft: 2,
          }}
        >
          {errors.firstName}
        </Typography>
        )}
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Box flex={1}>
            <Typography
              sx={{
                fontFamily: "Avenir",
                fontSize: "14px",
                paddingLeft: "15px",
              }}
            >
              Last name
            </Typography>
            <TextField
              value={lastName}
              onChange={handleLastNameChange}
              fullWidth
              // helperText={errors.lastName}
              // error={Boolean(errors.lastName)}
              InputProps={{
                style: {
                  paddingRight: "30px",
                  height: "40px",
                  marginTop: 5,
                  borderRadius: 5,
                },
                IconComponent: () => null,
              }}
            />
            {errors.lastName && (
          <Typography
          sx={{
            fontSize: "13px",
            color: "#de6161",
            fontFamily: "Avenir", 
            marginBottom: 2,
            marginLeft: 2,
          }}
        >
          {errors.lastName}
        </Typography>
        )}
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Box flex={1}>
            <Typography
              sx={{
                fontFamily: "Avenir",
                fontSize: "14px",
                paddingLeft: "15px",
              }}
            >
              Email
            </Typography>
            <TextField
              value={email}
              onChange={handleEmailChange}
              fullWidth
              // helperText={errors.email}
              // error={Boolean(errors.email)}
              InputProps={{
                style: {
                  paddingRight: "30px",
                  height: "40px",
                  marginTop: 5,
                  borderRadius: 5,
                },
                IconComponent: () => null,
              }}
            />
            {errors.email && (
          <Typography
          sx={{
            fontSize: "13px",
            color: "#de6161",
            fontFamily: "Avenir", 
            marginBottom: 2,
            marginLeft: 2,
          }}
        >
          {errors.email}
        </Typography>
        )}
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Box flex={1}>
            <Typography
              sx={{
                fontFamily: "Avenir",
                fontSize: "14px",
                paddingLeft: "15px",
              }}
            >
              Phone
            </Typography>
            <TextField
              value={phone}
              onChange={handlePhoneChange}
              fullWidth
              // helperText={errors.phone}
              // error={Boolean(errors.phone)}
              InputProps={{
                style: {
                  paddingRight: "30px",
                  height: "40px",
                  marginTop: 5,
                  borderRadius: 5,
                },
                IconComponent: () => null,
              }}
            />
            {errors.phone && (
          <Typography
          sx={{
            fontSize: "13px",
            color: "#de6161",
            fontFamily: "Avenir", 
            marginBottom: 2,
            marginLeft: 2,
          }}
        >
          {errors.phone}
        </Typography>
        )}
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Box flex={1}>
            <Typography
              sx={{
                fontFamily: "Avenir",
                fontSize: "14px",
                paddingLeft: "15px",
              }}
            >
              City, Country
            </Typography>
            <TextField
              value={city}
              onChange={handleCityChange}
              fullWidth
              // helperText={errors.city}
              // error={Boolean(errors.city)}
              InputProps={{
                style: {
                  paddingRight: "30px",
                  height: "40px",
                  marginTop: 5,
                  borderRadius: 5,
                },
                IconComponent: () => null,
              }}
            />
            {errors.city && (
          <Typography
          sx={{
            fontSize: "13px",
            color: "#de6161",
            fontFamily: "Avenir", 
            marginBottom: 2,
            marginLeft: 2,
          }}
        >
          {errors.city}
        </Typography>
        )}
          </Box>
        </Box>

        <Typography
          sx={{
            fontFamily: "Avenir",
            fontSize: "14px",
            paddingLeft: "15px",
          }}
        >
          Your Resume
        </Typography>
        <Box sx={{ padding: 3 }}>
          <div id="id" onClick={handleOpenClick} style={{ cursor: "pointer" }}>
            <img src={Upload} alt="ImageUpload" style={{ width: "100%" }} />
            <input
              id="yourinputname"
              type="file"
              name="yourinputname"
              style={{ display: "none" }}
              onChange={(e) => handleSelectedFile(e.target.files)}
              ref={fileInputRef}
            />
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "right",
              alignItems: "end",
            }}
          >
            <img src={Limit} alt="Limitation" />
          </div>
          {errors.resume && (
          <Typography
          sx={{
            fontSize: "13px",
            color: "#de6161",
            fontFamily: "Avenir", 
            marginBottom: 2
          }}
        >
          {errors.resume}
        </Typography>
        )}
        </Box>
     
        <Box sx={{ mt: "-50px", mb: 3 }}>
          {selectedFiles.length > 0 && (
            <Typography
              sx={{ fontSize: "14px", textAlign: "center", marginTop: 2 }}
            >
              Selected Files:{" "}
              {selectedFiles.map((file) => file.name).join(", ")}
            </Typography>
          )}
        </Box>
        <Box sx={{ gap: 2, mb: 1 }}>
          <Box>
            <Typography
              sx={{
                fontFamily: "Avenir",
                fontSize: "14px",
                paddingLeft: "15px",
              }}
            >
              Your Experience?
            </Typography>
            <TextField
              value={experience}
              onChange={handleExperiencChange}
              fullWidth
              // helperText={errors.experience}
              // error={Boolean(errors.experience)}
              InputProps={{
                style: {
                  paddingRight: "30px",
                  height: "40px",
                  marginTop: 5,
                  borderRadius: 5,
                },
                IconComponent: () => null,
              }}
            />
             {errors.experience && (
          <Typography
          sx={{
            fontSize: "13px",
            color: "#de6161",
            fontFamily: "Avenir", 
            marginBottom: 2,
            marginLeft: 2,
          }}
        >
          {errors.experience}
        </Typography>
        )}
          </Box>
        </Box>

        <Box sx={{ mb: "10px" }}>
          <Typography
            sx={{ fontFamily: "Avenir", fontSize: "14px", paddingLeft: "15px" }}
          >
            Wishing to relocate?
          </Typography>
          <RadioGroup
            value={relocationOption}
            onChange={handleRelocationChange}
            row
            // helperText={errors.relocationOption}
            // error={Boolean(errors.relocationOption)}
          >
            <FormControlLabel
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
              value="Yes"
              control={<Radio />}
              label="Yes, I am planning to relocate"
            />
            <FormControlLabel
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
              value="No"
              control={<Radio />}
              label="No, I am not planning to relocate"
            />
          </RadioGroup>
          {errors.relocationOption && (
          <Typography
          sx={{
            fontSize: "13px",
            color: "#de6161",
            fontFamily: "Avenir", 
            marginBottom: 2,
            marginLeft: 2,
          }}
        >
          {errors.relocationOption}
        </Typography>
        )}
        </Box>
      </DialogContent>

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <svg
          width="32"
          height="2"
          viewBox="0 0 32 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.71"
            d="M1 1H31"
            stroke="#222832"
            stroke-linecap="round"
          />
        </svg>
      </div>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleApplyJob}
          sx={{
            bgcolor: "#2C2F3D",
            width: "192px",
            color: "white",
            borderRadius: 50,
            mb: 2,
            "&:hover": {
              bgcolor: "#2C2F3D",
              color: "white",
            },
          }}
        >
          Apply Job
        </Button>
      </DialogActions>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default Job;
