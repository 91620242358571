import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import LPG1 from "../../../../assets/pages/landingPage/SubServices/LPG1.png";
import LPG2 from "../../../../assets/pages/landingPage/SubServices/LPG2.png";
import OD1 from "../../../../assets/pages/landingPage/SubServices/OD1.png";
import OD2 from "../../../../assets/pages/landingPage/SubServices/OD2.png";
import OO1 from "../../../../assets/pages/landingPage/SubServices/OO1.png";
import OO2 from "../../../../assets/pages/landingPage/SubServices/OO2.png";
import TRL1 from "../../../../assets/pages/landingPage/SubServices/TRL1.png";
import TRL2 from "../../../../assets/pages/landingPage/SubServices/TRL2.png";
import TowardsRight from "./TowardsRight";
import TowardsLeft from "./TowardsLeft";

function PetroChemical() {
	return (
		<Box sx={{ marginTop: "2%" }}>
			<TowardsRight title="Refineries and Oil Storage Depots" number="01" image1={OD1} image2={OD2}/>
			<TowardsLeft title="LPG Bottling Plants" number="02" image1={LPG1} image2={LPG2}/>
			<TowardsRight title="Truck Loading Facilities" number="03" image1={TRL1} image2={TRL2}/>
			<TowardsLeft title="Onshore/Offshore Facilities" number="04" image1={OO1} image2={OO2}/>
		</Box>
	);
}

export default PetroChemical;
