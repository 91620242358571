import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ProductEngineering from "./ProductEngineering";
import PowerSector from "./PowerSector";
import PetroChemical from "./PetroChemical";
import LifeCycleHealthCare from "./LifeCycleHealthCare";
import OperationMaintanance from "./OperationMaintanance";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

function MServicesMainTab() {
  const initialValue = localStorage.getItem("services");
  const [activeTab, setActiveTab] = useState(initialValue);
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <section id="servicesmaintab-section">
      <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "#F9F9FA",
          minHeight: "100vh",
          "@media (min-width: 1441px)": {
            minHeight: "100vh",
          },
          "@media (max-width: 830px)": {
            minHeight: "auto",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "1440px", width: "100%" }}>
          <Box>
            <Box
              sx={{
                marginRight: "5%",
                paddingTop: "120px",
                marginBottom: "1%",
                "@media (max-width: 810px)": {
                  marginLeft: "2%",
                },
              }}
            >
              <Box sx={{ marginLeft: "5%" }}>
                <Box
                  sx={{
                    display: "flex",
                    overflow: "scroll",
                    borderRadius: "20px 20px 20px 20px",
                    backgroundColor: "rgba(242, 242, 242, 1)",
                    maxWidth: "100%",
                  }}
                >
                  <Box
                    onClick={() => handleTabClick("productEngineering")}
                    sx={{
                      backgroundColor:
                        activeTab === "productEngineering"
                          ? "rgba(50, 54, 60, 1)"
                          : "transparent",
                      borderRadius: "20px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      whiteSpace: "nowrap",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          activeTab === "productEngineering"
                            ? "white"
                            : "#22242F",
                        fontWeight: 500,
                        fontSize: "12px",
                        fontFamily: "Avenir",
                      }}
                    >
                      Project Engineering
                    </Typography>
                  </Box>

                  <Box
                    onClick={() => handleTabClick("powerSectorProjects")}
                    sx={{
                      backgroundColor:
                        activeTab === "powerSectorProjects"
                          ? "rgba(50, 54, 60, 1)"
                          : "transparent",
                      borderRadius: "20px 20px 20px 20px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      whiteSpace: "nowrap",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          activeTab === "powerSectorProjects"
                            ? "white"
                            : "#22242F",
                        fontWeight: 500,
                        fontSize: "12px",
                        fontFamily: "Avenir",
                      }}
                    >
                      Power Sector Projects
                    </Typography>
                  </Box>

                  <Box
                    onClick={() => handleTabClick("petroChemicalProjects")}
                    sx={{
                      backgroundColor:
                        activeTab === "petroChemicalProjects"
                          ? "rgba(50, 54, 60, 1)"
                          : "transparent",
                      borderRadius: "20px 20px 20px 20px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      whiteSpace: "nowrap",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          activeTab === "petroChemicalProjects"
                            ? "white"
                            : "#22242F",
                        fontWeight: 500,
                        fontSize: "12px",
                        fontFamily: "Avenir",
                      }}
                    >
                      Petro Chemical Projects
                    </Typography>
                  </Box>

                  <Box
                    onClick={() => handleTabClick("lifeScience")}
                    sx={{
                      backgroundColor:
                        activeTab === "lifeScience"
                          ? "rgba(50, 54, 60, 1)"
                          : "transparent",
                      borderRadius: "20px 20px 20px 20px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "25px",
                      whiteSpace: "nowrap",
                      paddingRight: "25px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          activeTab === "lifeScience" ? "white" : "#22242F",
                        fontWeight: 500,
                        fontSize: "12px",
                        fontFamily: "Avenir",
                      }}
                    >
                      Life Science & Healthcare Projects
                    </Typography>
                  </Box>

                  {/* <Box
										onClick={() => handleTabClick("operationMaintanance")}
										sx={{
											backgroundColor:
												activeTab === "operationMaintanance"
													? "rgba(50, 54, 60, 1)"
													: "transparent",
											borderRadius: "20px 20px 20px 20px",
											paddingTop: "10px",
											whiteSpace: "nowrap",
											paddingBottom: "10px",
											paddingLeft: "25px",
											paddingRight: "25px",
											cursor: "pointer",
										}}
									>
										<Typography
											sx={{
												color:
													activeTab === "operationMaintanance"
														? "white"
														: "#22242F",
												fontWeight: 500,
												fontSize: "12px",
												fontFamily: "Avenir",
											}}
										>
											Operation & Maintenance of facilities
										</Typography>
									</Box> */}
                </Box>
              </Box>
              {activeTab !== "productEngineering" && (
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "3%",
					marginLeft: '15px',
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Avenir",
                      fontWeight: 500,
                      fontSize: "15px",
                      textAlign: "left",
                      color: "#202632",
                    }}
                  >
                    Engineering Design and Project Management of -
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  marginTop: "3%",
                  marginLeft: "5%",
                  scrollbarWidth: "thin",
                  msOverflowStyle: "none",
                  "&::-webkit-scrollbar": {
                    width: "5px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "transparent",
                  },
                  "&:hover": {
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                    },
                  },
                }}
              >
                <div
                  className={
                    activeTab === "productEngineering"
                      ? "fade-in-out"
                      : "fade-in-out-hidden"
                  }
                >
                  {activeTab === "productEngineering" && <ProductEngineering />}
                </div>

                <div
                  className={
                    activeTab === "powerSectorProjects"
                      ? "fade-in-out"
                      : "fade-in-out-hidden"
                  }
                >
                  {activeTab === "powerSectorProjects" && <PowerSector />}
                </div>

                <div
                  className={
                    activeTab === "petroChemicalProjects"
                      ? "fade-in-out"
                      : "fade-in-out-hidden"
                  }
                >
                  {activeTab === "petroChemicalProjects" && <PetroChemical />}
                </div>

                <div
                  className={
                    activeTab === "lifeScience"
                      ? "fade-in-out"
                      : "fade-in-out-hidden"
                  }
                >
                  {activeTab === "lifeScience" && <LifeCycleHealthCare />}
                </div>

                <div
                  className={
                    activeTab === "operationMaintanance"
                      ? "fade-in-out"
                      : "fade-in-out-hidden"
                  }
                >
                  {activeTab === "operationMaintanance" && (
                    <OperationMaintanance />
                  )}
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </section>
  );
}

export default MServicesMainTab;
