import { useEffect, useState } from "react";
import CircleMenu from "../../assets/pages/navbar/circle";
import logo from "../../assets/pages/navbar/logo.svg";
import logo1 from "../../assets/pages/navbar/logo1.svg";
import { display } from "@mui/system";

const NavBar = () => {
	const [showLogo1, setShowLogo1] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [initial, setInitial] = useState(false);

	useEffect(() => {
		if (
			window.location.pathname === "/services" ||
			window.location.pathname === "/jobs"
		) {
			setShowLogo1(true);
		} else {
			setShowLogo1(false);
		}
		const handleScroll = () => {
			const scrollY = window.scrollY;
			const threshold = 100; // Adjust this threshold as needed

			setShowLogo1(scrollY > threshold);
			if (
				window.location.pathname === "/services" ||
				window.location.pathname === "/jobs"
			) {
				setShowLogo1(true);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const[whatPage, setWhatPage] = useState("");

	useEffect(() => {
		var isContact = localStorage.getItem("isContact")
		if(isContact === "true"){
			const what = localStorage.getItem("whatPage")
			scrollToSection(what)
			localStorage.setItem("isContact", "false")
			localStorage.setItem("whatPage", "")
		}
	}, []);

	const whichPage = (pagename) => {
		const currentPage = window.location.href;
		if(currentPage !== "https://hertzandhenry.com/"){
			localStorage.setItem("isContact", "true")
			localStorage.setItem("whatPage", pagename)
			setWhatPage(pagename)
		}
		else{
			scrollToSection(pagename)
		}
		scrollToSection(pagename)
	}
	

	const scrollToSection = (sectionId) => {
		if (
			window.location.pathname === "/services" ||
			window.location.pathname === "/jobs"
		) {
			window.location = "/";
		}
		const section = document.getElementById(sectionId);

		if (section) {
			section.scrollIntoView({
				behavior: "smooth",
			});
		}
	};

	return (
		<>
			<div
				className={"navcontainer"}
				style={{ zIndex: showLogo1 || showMenu ? 9 : "inherit" }}
			>
				<nav className='navbar'>
					<a href='/'>
						<img
							src={showLogo1 || showMenu ? logo1 : logo}
							alt='Logo'
							style={{ position: "relative", zIndex: "999" }}
						/>
					</a>
					<div className='menu-container'>
						<ul className={showLogo1 ? "menu1" : "menu"}>
							<li
								onClick={() => whichPage("home-section")}
								style={{ cursor: "pointer" }}
							>
								Home
							</li>
							<li
								onClick={() => whichPage("aboutus-section")}
								style={{ cursor: "pointer" }}
							>
								About
							</li>
							<li
								onClick={() => whichPage("process-section")}
								style={{ cursor: "pointer" }}
							>
								Approach
							</li>
							<li
								onClick={() => whichPage("whyus-section")}
								style={{ cursor: "pointer" }}
							>
								Why us
							</li>
							<li
								onClick={() => whichPage("services-section")}
								style={{ cursor: "pointer" }}
							>
								Services
							</li>
							<a href='/jobs'>
								<li>Jobs</li>
							</a>
							<CircleMenu />
						</ul>
						<div className='loginNav'>
							<button
								className='contact_button'
								onClick={() => whichPage("contactus-section")}
							>
								Contact
							</button>
							<a href='https://admin.hertzandhenry.com/login' target='blank'>
								<button className='login_button'>Login</button>
							</a>
						</div>
					</div>
					<div
						className='menubutton'
						style={{
							backgroundColor: showMenu || showLogo1 ? "black" : "white",
							color: showMenu || showLogo1 ? "white" : "black",
						}}
						onClick={() => {
							setShowMenu(!showMenu);
							setInitial(true);
						}}
					>
						<p>Menu</p>
						<div
							className='circle-menu'
							style={{
								backgroundColor: showMenu || showLogo1 ? "white" : "black",
							}}
						></div>
					</div>
					<div
						className={showMenu ? "glass" : "glass-reverse"}
						style={{ display: initial ? "block" : "none" }}
					>
						<ul className='mobilemenulist' style={{ color: "black" }}>
							<li
								onClick={() => {
									scrollToSection("mobile-home-section");
									setShowMenu(false);
								}}
								style={{ cursor: "pointer" }}
							>
								Home
							</li>
							<li
								onClick={() => {
									scrollToSection("mobile-about-section");
									setShowMenu(false);
								}}
								style={{ cursor: "pointer" }}
							>
								About
							</li>
							<li
								onClick={() => {
									scrollToSection("mobile-process-section");
									setShowMenu(false);
								}}
								style={{ cursor: "pointer" }}
							>
								Approach
							</li>
							<li
								onClick={() => {
									scrollToSection("mobile-whyus-section");
									setShowMenu(false);
								}}
								style={{ cursor: "pointer" }}
							>
								Why us
							</li>
							<li
								onClick={() => {
									scrollToSection("mobile-services-section");
									setShowMenu(false);
								}}
								style={{ cursor: "pointer" }}
							>
								Services
							</li>
							<li
								onClick={() => {
									scrollToSection("mobile-contact-section");
									setShowMenu(false);
								}}
								style={{ cursor: "pointer" }}
							>
								Contact
							</li>
							<a href='https://admin.hertzandhenry.com/login' target='blank'>
								<li>Login</li>
							</a>
						</ul>
					</div>
				</nav>
			</div>
		</>
	);
};
export default NavBar;
