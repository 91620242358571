import { useEffect, useState } from "react";
import "./App.css";
import NavBar from "./components/common/navbar";
import AboutUs from "./components/pages/landingPage/AboutUs";
import ContactUs from "./components/pages/landingPage/ContactUs";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/pages/landingPage/Home";
import Process from "./components/pages/landingPage/Process";
import Services from "./components/pages/landingPage/Services";
import ServicesMainTab from "./components/pages/landingPage/Services/ServicesMainTab";
import WhyUs from "./components/pages/landingPage/WhyUs";
import MWhyUs from "./components/pages/landingPage/Mobile/MWhyUs";
import MServices from "./components/pages/landingPage/Mobile/MServices";
import MProcess from "./components/pages/landingPage/Mobile/MProcess";
import MAbout from "./components/pages/landingPage/Mobile/MAbout";
import MHome from "./components/pages/landingPage/Mobile/MHome";
import MContact from "./components/pages/landingPage/Mobile/MContact";
import MFooter from "./components/pages/landingPage/Mobile/MFooter";
import MServicesMainTab from "./components/pages/landingPage/Mobile/Services/ServicesMainTab";
import { Jobs } from "./components/pages/landingPage/job";
import { Box } from "@mui/material";

function App() {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			<Router>
				{windowWidth > 900 ? (
					<>
						<NavBar />
						<Routes>
							<Route path='/services' element={<ServicesMainTab />} />
							<Route path='/jobs' element={<Jobs />} />
							<Route
								path='/'
								element={
									<Box sx={{backgroundColor: "#F9F9FA"}}>
										<Home />
										<AboutUs />
										<Process />
										<WhyUs />
										<Services />
										<ContactUs />
									</Box>
								}
							/>
						</Routes>
					</>
				) : (
					<>
						<NavBar />
						<Routes>
							<Route path='/services' element={<MServicesMainTab />} />
							<Route path='/jobs' element={<Jobs />} />
							<Route
								path='/'
								element={
									<>
										<MHome />
										<MAbout />
										<MProcess />
										<MWhyUs />
										<MServices />
										<MContact />
										<MFooter />
									</>
								}
							/>
						</Routes>
					</>
				)}
			</Router>
		</>
	);
}

export default App;
