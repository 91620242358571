import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ProductEngineering from "./ProductEngineering";
import PowerSector from "./PowerSector";
import PetroChemical from "./PetroChemical";
import LifeCycleHealthCare from "./LifeCycleHealthCare";
import OperationMaintanance from "./OperationMaintanance";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { Link } from "react-router-dom";

function ServicesMainTab() {
  const initialValue = localStorage.getItem("services");
  const [activeTab, setActiveTab] = useState(initialValue);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <section id="servicesmaintab-section">
      <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "#F9F9FA",
          minHeight: "100vh",
          "@media (min-width: 1441px)": {
            minHeight: "100vh",
          },
          "@media (max-width: 830px)": {
            minHeight: "auto",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "1440px", width: "100%" }}>
          <Box>
            <Box
              sx={{
                marginRight: "5%",
                paddingTop: "120px",
                marginBottom: "1%",
                "@media (max-width: 810px)": {
                  marginLeft: "2%",
                },
              }}
            >
              <Box sx={{ marginLeft: "5%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      borderRadius: "20px 20px 20px 20px",
                      backgroundColor: "rgba(242, 242, 242, 1)",
                      maxWidth: "88%",
                    }}
                  >
                    <Box
                      onClick={() => handleTabClick("productEngineering")}
                      sx={{
                        backgroundColor:
                          activeTab === "productEngineering"
                            ? "rgba(50, 54, 60, 1)"
                            : "transparent",
                        borderRadius: "20px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            activeTab === "productEngineering"
                              ? "white"
                              : "#22242F",
                          fontWeight: 500,
                          fontSize: "14px",
                          fontFamily: "Avenir",
                        }}
                      >
                        Project Management / Project Engineering
                      </Typography>
                    </Box>

                    <Box
                      onClick={() => handleTabClick("powerSectorProjects")}
                      sx={{
                        backgroundColor:
                          activeTab === "powerSectorProjects"
                            ? "rgba(50, 54, 60, 1)"
                            : "transparent",
                        borderRadius: "20px 20px 20px 20px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            activeTab === "powerSectorProjects"
                              ? "white"
                              : "#22242F",
                          fontWeight: 500,
                          fontSize: "14px",
                          fontFamily: "Avenir",
                        }}
                      >
                        Power Sector Projects
                      </Typography>
                    </Box>

                    <Box
                      onClick={() => handleTabClick("petroChemicalProjects")}
                      sx={{
                        backgroundColor:
                          activeTab === "petroChemicalProjects"
                            ? "rgba(50, 54, 60, 1)"
                            : "transparent",
                        borderRadius: "20px 20px 20px 20px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            activeTab === "petroChemicalProjects"
                              ? "white"
                              : "#22242F",
                          fontWeight: 500,
                          fontSize: "14px",
                          fontFamily: "Avenir",
                        }}
                      >
                        Petro Chemical Projects
                      </Typography>
                    </Box>

                    <Box
                      onClick={() => handleTabClick("lifeScience")}
                      sx={{
                        backgroundColor:
                          activeTab === "lifeScience"
                            ? "rgba(50, 54, 60, 1)"
                            : "transparent",
                        borderRadius: "20px 20px 20px 20px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            activeTab === "lifeScience" ? "white" : "#22242F",
                          fontWeight: 500,
                          fontSize: "14px",
                          fontFamily: "Avenir",
                        }}
                      >
                        Life Science & Healthcare Projects
                      </Typography>
                    </Box>

                    {/* <Box
											onClick={() => handleTabClick("operationMaintanance")}
											sx={{
												backgroundColor:
													activeTab === "operationMaintanance"
														? "rgba(50, 54, 60, 1)"
														: "transparent",
												borderRadius: "20px 20px 20px 20px",
												paddingTop: "10px",
												paddingBottom: "10px",
												paddingLeft: "25px",
												paddingRight: "25px",
												cursor: "pointer",
											}}
										>
											<Typography
												sx={{
													color:
														activeTab === "operationMaintanance"
															? "white"
															: "#22242F",
													fontWeight: 500,
													fontSize: "14px",
													fontFamily: "Avenir",
												}}
											>
												Operation & Maintenance of facilities
											</Typography>
										</Box> */}
                  </Box>
                  <a href="/">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        style={{
                          textDecoration: "underline",
                          textUnderlineOffset: "2px",
                          fontSize: "20px",
                          fontFamily: "Avenir",
                          color: "black",
                        }}
                      >
                        Home
                      </Typography>
                      <i>
                        <svg
                          width="13"
                          height="10"
                          viewBox="0 0 13 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 4.35C0.641015 4.35 0.35 4.64101 0.35 5C0.35 5.35899 0.641015 5.65 1 5.65V4.35ZM12.4596 5.45962C12.7135 5.20578 12.7135 4.79422 12.4596 4.54038L8.32304 0.403806C8.0692 0.149965 7.65765 0.149965 7.40381 0.403806C7.14997 0.657647 7.14997 1.0692 7.40381 1.32304L11.0808 5L7.40381 8.67696C7.14997 8.9308 7.14997 9.34235 7.40381 9.59619C7.65765 9.85003 8.0692 9.85003 8.32304 9.59619L12.4596 5.45962ZM1 5.65H12V4.35H1V5.65Z"
                            fill="#161B26"
                          />
                        </svg>
                      </i>
                    </Box>
                  </a>
                </Box>
              </Box>
              {activeTab !== "productEngineering" && (
					<Box
						sx={{
						display: "flex",
						marginTop: "3%",
						alignItems: "center",
						justifyContent: "center",
						}}
					>
						<Typography
						sx={{
							fontFamily: "Avenir",
							fontWeight: 500,
							fontSize: "18px",
							textAlign: "left",
							color: "#202632",
						}}
						>
							Engineering Design and Project Management of -
						</Typography>
					</Box>
					)}
              <Box
                sx={{
                  marginTop: "1%",
                  marginLeft: "5%",
                  maxHeight: "67vh",
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                  msOverflowStyle: "none",
                  "&::-webkit-scrollbar": {
                    width: "5px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "transparent",
                  },
                  "&:hover": {
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                    },
                  },
                }}
              >
                <div
                  className={
                    activeTab === "productEngineering"
                      ? "fade-in-out"
                      : "fade-in-out-hidden"
                  }
                >
                  {activeTab === "productEngineering" && <ProductEngineering />}
                </div>

                <div
                  className={
                    activeTab === "powerSectorProjects"
                      ? "fade-in-out"
                      : "fade-in-out-hidden"
                  }
                >
                  {activeTab === "powerSectorProjects" && <PowerSector />}
                </div>

                <div
                  className={
                    activeTab === "petroChemicalProjects"
                      ? "fade-in-out"
                      : "fade-in-out-hidden"
                  }
                >
                  {activeTab === "petroChemicalProjects" && <PetroChemical />}
                </div>

                <div
                  className={
                    activeTab === "lifeScience"
                      ? "fade-in-out"
                      : "fade-in-out-hidden"
                  }
                >
                  {activeTab === "lifeScience" && <LifeCycleHealthCare />}
                </div>

                <div
                  className={
                    activeTab === "operationMaintanance"
                      ? "fade-in-out"
                      : "fade-in-out-hidden"
                  }
                >
                  {activeTab === "operationMaintanance" && (
                    <OperationMaintanance />
                  )}
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </section>
  );
}

export default ServicesMainTab;
