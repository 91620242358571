import { useState } from "react";
import { ApplyNowArrow, ApplyNowArrowMob } from "./jobArrow";
import Job from "./Job";
import { Box } from "@mui/material";

export const JobCard = ({ data, filterStatus }) => {
  const [openEmployeeLeave, setOpenEmployeeLeave] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const filteredJobs = data.filter((job) => job.status === "ACTIVE");


const handleOpenEmployeeLeave = (jobId) => {

    setOpenEmployeeLeave(true);
    setSelectedJobId(jobId);
    console.log("Clicked on Job ID:", jobId);

};

  const handleCloseEmployeeLeave = async () => {
    setOpenEmployeeLeave(false);
  };

  console.log("This is", data);
  return (
    <>
      {filteredJobs.map((job, index) => (
        <div
          key={index}
          className="p-5 w-full bg-white rounded-xl sm:text-lg text-sm mb-4"
        >
          <h1 className="mb-4" sx={{ fontWeight: 600 }}>
            {job.title}
          </h1>
          <div style={{fontSize: '15px',  fontFamily: "Avenir", marginBottom: 12}}>
            <p>
              {job.tags}
            </p>
          </div>
          <div className="flex gap-4 items-center mb-4 flex-wrap">
            <p>
              Office location: {job.office_location} | Experience:{" "}
              {job.experience} + years
            </p>
          </div>
          <div className="flex w-full justify-between flex-col xs:flex-col sm:flex-row gap-4">
            <div className="flex gap-4 flex-wrap">
              <p className="px-2 py-0.5 bg-[#F1F1F1] rounded-md">
                LPA : {job.salary}
              </p>
              
              <p className="px-2 py-0.5 bg-[#F1F1F1] rounded-md">
                Relocation: {job.relocation_Y_N}
              </p>
            </div>
            <div onClick={() => handleOpenEmployeeLeave(job.job_id)} className="gap-2 hidden sm:flex cursor-pointer">
              <Box >Apply Now</Box>
              <ApplyNowArrow />
            </div>
            <div onClick={() => handleOpenEmployeeLeave(job.job_id)} className="gap-2 flex py-2 px-3 items-center bg-[#32363C] w-max rounded-full text-white sm:hidden cursor-pointer">
              <Box>Apply Now</Box>
              <ApplyNowArrowMob />
            </div>
          </div>
        </div>
      ))}
      <Job open={openEmployeeLeave} jobId={selectedJobId} onClose={handleCloseEmployeeLeave} />
    </>
  );
};
