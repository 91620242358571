import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PE1 from "../../../../../assets/pages/landingPage/SubServices/PE1.png";
import DE1 from "../../../../../assets/pages/landingPage/SubServices/DE1.png";
import PS1 from "../../../../../assets/pages/landingPage/SubServices/PS1.png";
import EIA from "../../../../../assets/pages/landingPage/SubServices/EIA1.png";
import PC1 from "../../../../../assets/pages/landingPage/SubServices/PC1.png";
import SA1 from "../../../../../assets/pages/landingPage/SubServices/SA1.png";
import DEE1 from "../../../../../assets/pages/landingPage/SubServices/DE1.png";
import RFP1 from "../../../../../assets/pages/landingPage/SubServices/RFP1.png";
import PSB2 from "../../../../../assets/pages/landingPage/SubServices/PSB2.png";
import ACS1 from "../../../../../assets/pages/landingPage/SubServices/ACS1.png";
import DSE2 from "../../../../../assets/pages/landingPage/SubServices/DSE2.png";
import HAZOP1 from "../../../../../assets/pages/landingPage/SubServices/HAZOP1.png";
import BOQ2 from "../../../../../assets/pages/landingPage/SubServices/BOQ2.png";
import FT1 from "../../../../../assets/pages/landingPage/SubServices/FT1.png";
import RCS2 from "../../../../../assets/pages/landingPage/SubServices/RCS2.png";
import DOC1 from "../../../../../assets/pages/landingPage/SubServices/DOC1.png";
import FAT2 from "../../../../../assets/pages/landingPage/SubServices/FAT2.png";
import CWV1 from "../../../../../assets/pages/landingPage/SubServices/CWV1.png";
import PMQ2 from "../../../../../assets/pages/landingPage/SubServices/PMQ2.png";
import ER1 from "../../../../../assets/pages/landingPage/SubServices/ER1.png";

const servicesData = [
	{
		image: PE1,
		title: "Front End Engineering\nDesign",
		description:"",
	},
	{
		image: DE1,
		title: "Detailed Engineering",
		description:"",
	},
	{
		image: PS1,
		title: "Power System Study",
		description:"",
	},
	{
		image: EIA,
		title: "Environmental Impact\nAssessments",
		description:"",
	},
	{
		image: PC1,
		title: "Project Costing",
		description:"",
	},
	{
		image: SA1,
		title: "Safety Assessments",
		description:"",
	},
	{
		image: PE1,
		title: "Feasibility Study",
		description:"",
	},
	{
		image: DEE1,
		title: "Development of DPRs",
		description:"",
	},
	{
		image: RFP1,
		title: "Development of project \nrequirement and RFP",
		description:"",
	},
	{
		image: PSB2,
		title: "Development of project \nspecification based on \ninternal standards",
		description:"",
	},
	{
		image: ACS1,
		title: "Area classification study",
		description:"",
	},
	{
		image: DSE2,
		title: "Development of \nspecification for equipment",
		description:"",
	},
	{
		image: HAZOP1,
		title: "HAZOP Study",
		description:"",
	},
	{
		image: BOQ2,
		title: "Development of BOQ",
		description:"",
	},
	{
		image: FT1,
		title: "Floating Tender, Bid \nEvaluation and Award \nRecommendation",
		description:"",
	},
	{
		image: RCS2,
		title: "Relay Coordination",
		description:"",
	},
	{
		image: DOC1,
		title: "Development of Construction",
		description:"",
	},
	{
		image: FAT2,
		title: "Conducting the FATs and SATs",
		description:"",
	},
	{
		image: CWV1,
		title: "Co-Ordination with Vendors",
		description:"",
	},
	{
		image: PMQ2,
		title: "Project Management and \nQuality",
		description:"",
	},
	{
		image: ER1,
		title: "Expertise Resource",
		description:"",
	},
	{
		image: PE1,
		title: "Motor Acceleration",
		description:"",
	},
];

function ProductEngineering() {
	return (
		<Box sx={{ marginTop: "2%" }}>
			<Box sx={{ position: "relative", marginLeft: "1%", marginRight: "1%" }}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						{servicesData.map((service, index) => (
							<Box key={index} sx={{ marginBottom: "7%", marginTop: "2%" }}>
								<Grid container spacing={1}>
									<Grid
										item
										xs={12}
										sx={{
											display: "flex",
											justifyContent: "center",
											flexDirection: "column",
										}}
									>
										<Grid item xs={12} style={{ marginBottom: "20px" }}>
											<img
												src={service.image}
												alt='engineering'
												style={{ width: "100%" }}
											/>
										</Grid>
										<Box
											sx={{
												borderBottom: "2px solid #202632",
												paddingBottom: "2%",
												width: "40%",
											}}
										>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontWeight: 500,
													fontSize: "17px",
													textAlign: "left",
													whiteSpace: "nowrap",
													color: "#202632",
												}}
											>
												{service.title.split("\n").map((line, index) => (
													<React.Fragment key={index}>
														{line}
														{index !== service.title.split("\n").length - 1 && (
															<br />
														)}
													</React.Fragment>
												))}
											</Typography>
										</Box>
										{/* <Box sx={{ marginTop: "3%" }}>
											<Typography
												sx={{
													fontFamily: "Avenir",
													fontWeight: 500,
													fontSize: "17px",
													textAlign: "left",
													color: "#202632",
													marginBottom: "20px",
												}}
											>
												Read more below
											</Typography>
										</Box> */}
									</Grid>
									<Grid item xs={12} sm={4}>
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<Typography
												sx={{
													position: "absolute",
													fontFamily: "Avenir",
													right: 0,
													marginTop: "-30px",
													fontWeight: 900,
													fontSize: { xs: "100px", sm: "100px", md: "100px" },
													textAlign: "right",
													color: "rgba(201, 201, 203, 1)",
													opacity: "33%",
													marginLeft: "10px",
												}}
											>
												{index + 1 < 10 ? `0${index + 1}` : index + 1}
											</Typography>
										</Box>
									</Grid>
								</Grid>

								<Box sx={{ width: "80%", marginTop: "-3%" }}>
									<Typography
										sx={{
											fontFamily: "Avenir",
											fontWeight: 500,
											fontSize: "16px",
											textAlign: "left",
											color: "#202632",
										}}
									>
										{service.description}
									</Typography>
								</Box>
								<a href='mailto:enquiry@hertzandhenry.com'>
									<Box
										sx={{
											display: "flex",
											marginTop: "2%",
											position: "relative",
											zIndex: "1000",
										}}
									>
										<Typography
											sx={{
												fontFamily: "Avenir",
												fontWeight: 500,
												fontSize: "16px",
												textAlign: "left",
												color: "#202632",
											}}
										>
											Need This Service
										</Typography>
										<svg
											style={{ marginLeft: "10px" }}
											width='22'
											height='22'
											viewBox='0 0 22 22'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<circle
												cx='11'
												cy='11'
												r='10.65'
												stroke='#161B26'
												strokeWidth='0.7'
											/>
											<path
												d='M9.53516 7.33203L12.4947 10.2916C12.8852 10.6821 12.8852 11.3153 12.4947 11.7058L9.53516 14.6654'
												stroke='#161B26'
												strokeLinecap='round'
											/>
										</svg>
									</Box>
								</a>
							</Box>
						))}
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default ProductEngineering;
