import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CF1 from "../../../../assets/pages/landingPage/SubServices/CF1.png";
import CF2 from "../../../../assets/pages/landingPage/SubServices/CF2.png";
import DL1 from "../../../../assets/pages/landingPage/SubServices/DL1.png";
import DL2 from "../../../../assets/pages/landingPage/SubServices/DL2.png";
import PPP1 from "../../../../assets/pages/landingPage/SubServices/PPP1.png";
import PPP2 from "../../../../assets/pages/landingPage/SubServices/PPP2.png";
import SP1 from "../../../../assets/pages/landingPage/SubServices/SP1.png";
import SP2 from "../../../../assets/pages/landingPage/SubServices/SP2.png";
import TL1 from "../../../../assets/pages/landingPage/SubServices/TL1.png";
import TL2 from "../../../../assets/pages/landingPage/SubServices/TL2.png";
import TowardsRight from "./TowardsRight";
import TowardsLeft from "./TowardsLeft";

function PowerSector() {
	return (
		<Box sx={{ marginTop: "2%" }}>
			<TowardsRight title="Power Plant Projects" number="01" image1={PPP1} image2={PPP2}/>
			<TowardsLeft title="Transmission Lines" number="02" image1={SP1} image2={SP2}/>
			<TowardsRight title="Substation Projects" number="03" image1={TL1} image2={TL2}/>
			<TowardsLeft title="Distribution Lines" number="04" image1={DL1} image2={DL2}/>
			<TowardsRight title="Consumer Facilities" number="05" image1={CF1} image2={CF2}/>
		</Box>
	);
}

export default PowerSector;
