import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import AE1 from "../../../../assets/pages/landingPage/SubServices/AE1.png";
import AE2 from "../../../../assets/pages/landingPage/SubServices/AE2.png";
import AM1 from "../../../../assets/pages/landingPage/SubServices/AM1.png";
import AM2 from "../../../../assets/pages/landingPage/SubServices/AM2.png";
import CF1 from "../../../../assets/pages/landingPage/SubServices/CF1.png";
import CF2 from "../../../../assets/pages/landingPage/SubServices/CF2.png";
import CP1 from "../../../../assets/pages/landingPage/SubServices/CP1.png";
import CP2 from "../../../../assets/pages/landingPage/SubServices/CP2.png";
import DE1 from "../../../../assets/pages/landingPage/SubServices/DE1.png";
import DE2 from "../../../../assets/pages/landingPage/SubServices/DE2.png";
import DL1 from "../../../../assets/pages/landingPage/SubServices/DL1.png";
import DL2 from "../../../../assets/pages/landingPage/SubServices/DL2.png";
import EIA1 from "../../../../assets/pages/landingPage/SubServices/EIA1.png";
import EIA2 from "../../../../assets/pages/landingPage/SubServices/EIA2.png";
import EP1 from "../../../../assets/pages/landingPage/SubServices/EP1.png";
import EP2 from "../../../../assets/pages/landingPage/SubServices/EP2.png";
import LH1 from "../../../../assets/pages/landingPage/SubServices/LH1.png";
import LH2 from "../../../../assets/pages/landingPage/SubServices/LH2.png";
import LPG1 from "../../../../assets/pages/landingPage/SubServices/LPG1.png";
import LPG2 from "../../../../assets/pages/landingPage/SubServices/LPG2.png";
import OD1 from "../../../../assets/pages/landingPage/SubServices/OD1.png";
import OD2 from "../../../../assets/pages/landingPage/SubServices/OD2.png";
import OP1 from "../../../../assets/pages/landingPage/SubServices/OP1.png";
import OP2 from "../../../../assets/pages/landingPage/SubServices/OP2.png";
import OO1 from "../../../../assets/pages/landingPage/SubServices/OO1.png";
import OO2 from "../../../../assets/pages/landingPage/SubServices/OO2.png";
import PC1 from "../../../../assets/pages/landingPage/SubServices/PC1.png";
import PC2 from "../../../../assets/pages/landingPage/SubServices/PC2.png";
import PE1 from "../../../../assets/pages/landingPage/SubServices/PE1.png";
import PE2 from "../../../../assets/pages/landingPage/SubServices/PE1.png";
import PPP1 from "../../../../assets/pages/landingPage/SubServices/PPP1.png";
import PPP2 from "../../../../assets/pages/landingPage/SubServices/PPP2.png";
import PS1 from "../../../../assets/pages/landingPage/SubServices/PS1.png";
import PS2 from "../../../../assets/pages/landingPage/SubServices/PS2.png";
import PSS1 from "../../../../assets/pages/landingPage/SubServices/PSS1.png";
import PSS2 from "../../../../assets/pages/landingPage/SubServices/PSS2.png";
import RP1 from "../../../../assets/pages/landingPage/SubServices/RP1.png";
import RP2 from "../../../../assets/pages/landingPage/SubServices/RP2.png";
import SA1 from "../../../../assets/pages/landingPage/SubServices/SA1.png";
import SA2 from "../../../../assets/pages/landingPage/SubServices/SA2.png";
import SP1 from "../../../../assets/pages/landingPage/SubServices/SP1.png";
import SP2 from "../../../../assets/pages/landingPage/SubServices/SP2.png";
import TL1 from "../../../../assets/pages/landingPage/SubServices/TL1.png";
import TL2 from "../../../../assets/pages/landingPage/SubServices/TL2.png";
import HAR1 from "../../../../assets/pages/landingPage/SubServices/HAR1.png";
import HAR2 from "../../../../assets/pages/landingPage/SubServices/HAR2.png";

import TowardsRight from "./TowardsRight";
import TowardsLeft from "./TowardsLeft";


function LifeCycleHealthCare() {
	return (
		<Box sx={{ marginTop: "2%" }}>
			<TowardsRight title="Complete Engineering solution for Hospitals" number="01" image1={EP1} image2={EP2}/>
			<TowardsLeft title="Complete Engineering solution for Pharmaceutical Industries" number="02" image1={CP1} image2={CP2}/>
			<TowardsRight title="Engineering Design and Project Management of Hotels and Resorts" number="03" image1={HAR1} image2={HAR2}/>
		</Box>
	);
}

export default LifeCycleHealthCare;
