import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ProjectManagementSpecialists from "../../../../assets/pages/landingPage/Home/projectmanagementspecialists.png";
import vectorLeftGrid from "../../../../assets/pages/landingPage/Home/vectorLeftGrid.svg";
import bottomLeftHex from "../../../../assets/pages/landingPage/Home/bottomLeftHex.svg";
import suitcase from "../../../../assets/pages/landingPage/Home/suitcase.svg";
import fiveplus from "../../../../assets/pages/landingPage/Home/5plus.svg";
import powersector from "../../../../assets/pages/landingPage/Home/powersector.svg";
import homeHero from "../../../../assets/pages/landingPage/Home/Mobile/bhHero.png";
import logo from "../../../../assets/pages/landingPage/Home/logo.svg";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { width } from "@mui/system";
import { Link } from "react-router-dom";

function MHome() {

	const scrollToSection = (sectionId) => {
		if (
			window.location.pathname === "/services" ||
			window.location.pathname === "/jobs"
		) {
			window.location = "/";
		}
		const section = document.getElementById(sectionId);

		if (section) {
			section.scrollIntoView({
				behavior: "smooth",
			});
		}
	};


	return (
		<section id='mobile-home-section'>
			<Box
				sx={{
					backgroundColor: "#080A0F",
					height: "100vh",
					"@media (min-width: 1441px)": {
						height: "100vh",
					},
					"@media (max-width: 900px)": {
						height: "auto",
					},
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box sx={{ maxWidth: "1440px", width: "100%" }}>
					<Box sx={{ paddingTop: "70px" }}>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={12}>
								<Box sx={{ marginTop: "5%" }}>
									<AnimationOnScroll
										animateIn='animate__fadeInUpBig'
										delay={100}
									>
										<img
											src={ProjectManagementSpecialists}
											style={{ width: "90%" }}
											alt='Project Management Specialists'
										/>
									</AnimationOnScroll>
									<Box sx={{ marginLeft: "10%", marginTop: "3%" }}>
										<AnimationOnScroll
											animateIn='animate__fadeInUpBig'
											delay={150}
										>
											<Typography
												sx={{
													fontFamily: "Sqwared Medium",
													color: "#CED1E2",
													fontWeight: 500,
													fontSize: {
														xs: "32px",
														sm: "40px",
														md: "60px",
														lg: "80px",
													},
												}}
											>
												Excellence in execution
											</Typography>
										</AnimationOnScroll>
										<AnimationOnScroll
											animateIn='animate__fadeInUpBig'
											delay={200}
										>
											<Box>
												<img
													src={vectorLeftGrid}
													alt='vector-left-grid'
													style={{
														width: "90%",
														height: "auto",
														marginLeft: "auto",
														marginTop: "5%",
													}}
												/>
											</Box>
										</AnimationOnScroll>
									</Box>
								</Box>
							</Grid>
							<AnimationOnScroll
								animateIn='animate__fadeInUpBig'
								delay={100}
								style={{ width: "100%" }}
							>
								<Box sx={{ display: "flex", alignItems: "center" }}>
									<Box
										sx={{
											marginLeft: "5%",
											backgroundImage: `url(${bottomLeftHex})`,
											backgroundSize: "contain",
											backgroundPosition: "center",
											backgroundRepeat: "no-repeat",
											paddingTop: "12px",
											paddingBottom: "12px",
											width: "100%",
										}}
									>
										<Grid
											container
											spacing={1}
											sx={{ width: "100%", flexWrap: "nowrap", gap: "20px" }}
										>
											<Grid item xs={4}>
											<Link to='/services' onClick={() =>
													localStorage.setItem("services", "productEngineering")
												}>
												<Box
													sx={{
														borderLeft: "2px solid rgba(239, 55, 51, 1)",
														paddingTop: "15px",
														paddingBottom: "15px",
														paddingLeft: "15px",
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}
												>
													<img
														src={suitcase}
														alt='suitcase'
														style={{ width: "33%" }}
													/>
													<Typography
														sx={{
															color: "rgba(255, 255, 255, 1)",
															marginLeft: "10px",
															fontFamily: "Avenir",
															fontSize: "10px",
														}}
													>
														Project <br />
														Engineering
													</Typography>
												</Box>
												</Link>
											</Grid>
											<Grid item xs={4} sm={4}>
											<Link to='/services' onClick={() =>
													localStorage.setItem("services", "powerSectorProjects")
												}>
												<Box
													sx={{
														borderLeft: "2px solid rgba(32, 38, 49, 1)",
														paddingTop: "15px",
														paddingBottom: "15px",
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}
												>
													<img
														src={powersector}
														alt='powersector'
														style={{ width: "33%" }}
													/>
													<Typography
														sx={{
															color: "rgba(255, 255, 255, 1)",
															marginLeft: "10px",
															fontFamily: "Avenir",
															whiteSpace: "nowrap",
															fontSize: "10px",
														}}
													>
														Power sector <br /> projects
													</Typography>
												</Box>
												</Link>
											</Grid>
											<Grid item xs={4} sm={4}>
											<a href="javascript:;" onClick={() => {
												scrollToSection("mobile-services-section");
											}}>
												<Box
													sx={{
														borderLeft: "2px solid rgba(32, 38, 49, 1)",
														paddingTop: "15px",
														paddingBottom: "15px",
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}
												>
													<img
														src={fiveplus}
														alt='fiveplus'
														style={{ width: "33%" }}
													/>
													<Typography
														sx={{
															color: "rgba(255, 255, 255, 1)",
															marginLeft: "10px",
															fontFamily: "Avenir",
															fontSize: "10px",
														}}
													>
														All <br />
														Projects
													</Typography>
												</Box>
												</a>
											</Grid>
										</Grid>
									</Box>
									{/* <img
										src={logo}
										alt='logo'
										style={{
											marginLeft: "auto",
											width: "200px",
											marginRight: "2%",
										}}
									/> */}
								</Box>
							</AnimationOnScroll>

							<Grid item xs={12}>
								<Box sx={{ marginTop: "10%" }}>
									<AnimationOnScroll
										animateIn='animate__fadeInUpBig'
										delay={100}
									>
										<img
											src={homeHero}
											style={{
												width: "100%",
											}}
											alt='homeHero'
										/>
									</AnimationOnScroll>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
		</section>
	);
}

export default MHome;
