import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import OM from "../../../../assets/pages/landingPage/services/OM.png";
import PMS from "../../../../assets/pages/landingPage/services/PMS.png";
import PP from "../../../../assets/pages/landingPage/services/PP.png";
import PSP from "../../../../assets/pages/landingPage/services/PSP.png";
import LSHC from "../../../../assets/pages/landingPage/services/LSHC.png";
import camShutter from "../../../../assets/pages/landingPage/Process/camShutter.svg";
import Bg from "../../../../assets/pages/landingPage/Process/bg.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

function MProcess() {
  return (
    <section id="mobile-process-section">
      <Box
        sx={{
          backgroundColor: "#F9F9FA",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ maxWidth: "1440px", width: "100%" }}>
          <Box
            sx={{
              marginRight: "8%",
              marginLeft: "8%",
              paddingTop: "70px",
              marginBottom: "1%",
            }}
          >
            <AnimationOnScroll animateIn="animate__fadeInUpBig" delay={100}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Avenir",
                    fontWeight: 900,
                    fontSize: "74px",
                    textAlign: "right",
                    color: "rgba(201, 201, 203, 1)",
                    opacity: "33%",
                  }}
                >
                  PROCESS
                </Typography>
              </Box>
            </AnimationOnScroll>
            <Box>
              <AnimationOnScroll animateIn="animate__fadeInUpBig" delay={200}>
                <Box
                  sx={{
                    backgroundColor: "white",
                    width: "100%",
                    marginTop: "-10%",
                  }}
                >
                  <Box sx={{marginLeft: '10%', marginRight: '5%', paddingTop: '10%', paddingBottom: '5%'}}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <svg
                        width="21"
                        height="2"
                        viewBox="0 0 21 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0.739258H20.8522" stroke="#F44946" />
                      </svg>
                      <Typography
                        sx={{
                          marginLeft: "5%",
                          fontSize: "12px",
                          fontWeight: 400,
                          fontFamily: "Avenir",
                          color: "#F44946",
                        }}
                      >
                        OUR APPROACH
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        marginTop: "5%",
                        marginLeft: "4%",
                        marginRight: "4%",
                        fontSize: "12px",
                        fontWeight: 400,
                        fontFamily: "Avenir",
                        color: "rgba(72, 75, 95, 1)",
                        textAlign: "left",
                      }}
                    >
                      We believe in a holistic approach to project
                      management. Our approach is rooted in a commitment to pushing the boundaries of what's possible. Our collaborative 
                      methodology ensures that your objectives are 
                      met on time and within budget, all while 
                      maintaining the highest quality standards. 
                    </Typography>
                    <Box sx={{ marginTop: "6%" }}>
						<img src={camShutter} alt="PSP" style={{ width: "100%" }} />
                    </Box>
                  </Box>
                </Box>
              </AnimationOnScroll>
			  <AnimationOnScroll animateIn="animate__fadeInUpBig" delay={200}>
				<Box>
					<img src={Bg} alt="bg" style={{width: '100%'}}/>
				</Box>
			  </AnimationOnScroll>
            </Box>
          </Box>
        </Box>
      </Box>
    </section>
  );
}

export default MProcess;
