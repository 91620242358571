import { Grid, Typography } from "@mui/material";
import { Box, width } from "@mui/system";
import React from "react";
import DE1 from "../../../../assets/pages/landingPage/SubServices/DE1.png";
import DE2 from "../../../../assets/pages/landingPage/SubServices/DE2.png";
import EIA1 from "../../../../assets/pages/landingPage/SubServices/EIA1.png";
import EIA2 from "../../../../assets/pages/landingPage/SubServices/EIA2.png";
import PC1 from "../../../../assets/pages/landingPage/SubServices/PC1.png";
import PC2 from "../../../../assets/pages/landingPage/SubServices/PC2.png";
import PE1 from "../../../../assets/pages/landingPage/SubServices/PE1.png";
import PE2 from "../../../../assets/pages/landingPage/SubServices/PE2.png";
import PS1 from "../../../../assets/pages/landingPage/SubServices/PS1.png";
import PS2 from "../../../../assets/pages/landingPage/SubServices/PS2.png";
import SA1 from "../../../../assets/pages/landingPage/SubServices/SA1.png";
import SA2 from "../../../../assets/pages/landingPage/SubServices/SA2.png";
import RFP1 from "../../../../assets/pages/landingPage/SubServices/RFP1.png";
import RFP2 from "../../../../assets/pages/landingPage/SubServices/RFP2.png";
import PSB1 from "../../../../assets/pages/landingPage/SubServices/PSB1.png";
import PSB2 from "../../../../assets/pages/landingPage/SubServices/PSB2.png";
import ACS1 from "../../../../assets/pages/landingPage/SubServices/ACS1.png";
import ACS2 from "../../../../assets/pages/landingPage/SubServices/ACS2.png";
import DSE1 from "../../../../assets/pages/landingPage/SubServices/DSE1.png";
import DSE2 from "../../../../assets/pages/landingPage/SubServices/DSE2.png";
import HAZOP1 from "../../../../assets/pages/landingPage/SubServices/HAZOP1.png";
import HAZOP2 from "../../../../assets/pages/landingPage/SubServices/HAZOP2.png";
import BOQ1 from "../../../../assets/pages/landingPage/SubServices/BOQ1.png";
import BOQ2 from "../../../../assets/pages/landingPage/SubServices/BOQ2.png";
import FT1 from "../../../../assets/pages/landingPage/SubServices/FT1.png";
import FT2 from "../../../../assets/pages/landingPage/SubServices/FT2.png";
import RCS1 from "../../../../assets/pages/landingPage/SubServices/RCS1.png";
import RCS2 from "../../../../assets/pages/landingPage/SubServices/RCS2.png";
import DOC1 from "../../../../assets/pages/landingPage/SubServices/DOC1.png";
import DOC2 from "../../../../assets/pages/landingPage/SubServices/DOC2.png";
import FAT1 from "../../../../assets/pages/landingPage/SubServices/FAT1.png";
import FAT2 from "../../../../assets/pages/landingPage/SubServices/FAT2.png";
import CWV1 from "../../../../assets/pages/landingPage/SubServices/CWV1.png";
import CWV2 from "../../../../assets/pages/landingPage/SubServices/CWV2.png";
import PMQ1 from "../../../../assets/pages/landingPage/SubServices/PMQ1.png";
import PMQ2 from "../../../../assets/pages/landingPage/SubServices/PMQ2.png";
import ER1 from "../../../../assets/pages/landingPage/SubServices/ER1.png";
import ER2 from "../../../../assets/pages/landingPage/SubServices/ER2.png";
import TowardsRight from "./TowardsRight";
import TowardsLeft from "./TowardsLeft";
import tools from "../../../../assets/pages/landingPage/SubServices/Tools.png";

function ProductEngineering() {

	return (
		<Box sx={{ marginTop: "2%" }}>
			<TowardsRight title="Front End Engineering Design" number="01" image1={PE1} image2={PE2}/>
			<TowardsLeft title="Detailed Engineering" number="02" image1={DE1} image2={DE2}/>
			<TowardsRight title="Power System Study" number="03" image1={PS1} image2={PS2}/>
			<TowardsLeft title="Environmental Impact Assessments" number="04" image1={EIA1} image2={EIA2}/>
			<TowardsRight title="Project Costing" number="05" image1={PC1} image2={PC2}/>
			<TowardsLeft title="Safety Assessments" number="06" image1={SA1} image2={SA2}/>
			<TowardsRight title="Feasibility Study" number="07" image1={PE1} image2={PE2}/>
			<TowardsLeft title="Development of DPRs" number="08" image1={PE1} image2={PE2}/>
			<TowardsRight title="Development of project requirement and RFP" number="09" image1={RFP1} image2={RFP2}/>
			<TowardsLeft title="Development of project specification based on  international standards" number="10" image1={PSB2} image2={PSB1}/>
			<TowardsRight title="Area classification study" number="11" image1={ACS1} image2={ACS2}/>
			<TowardsLeft title="Development of specification for equipment" number="12" image1={DSE2} image2={DSE1}/>
			<TowardsRight title="HAZOP Study" number="13" image1={HAZOP1} image2={HAZOP2}/>
			<TowardsLeft title="Development of BOQ" number="14" image1={BOQ2} image2={BOQ1}/>
			<TowardsRight title="Floating Tender, Bid Evaluation and Award Recommendation" number="15" image1={FT1} image2={FT2}/>
			<TowardsLeft title="Relay Coordination study, Cable/ equipment sizing, Harmonic Analysis, Short circuit Study" number="16" image1={RCS2} image2={RCS1}/>
			<TowardsRight title="Development of Construction Drawings, Layouts, Single Line Diagrams (SLDs), Schematics ..etc" number="17" image1={DOC1} image2={DOC2}/>
			<TowardsLeft title="Conducting the FATs and SATs (Factory Acceptance Test and Site Acceptance Test)" number="18" image1={FAT2} image2={FAT1}/>
			<TowardsRight title="Co-Ordination with Vendors" number="19" image1={CWV1} image2={CWV2}/>
			<TowardsLeft title="Project Management & Quality Assurance" number="20" image1={PMQ2} image2={PMQ1}/>
			<TowardsRight title="Expertise resources" number="21" image1={ER1} image2={ER2}/>
			<TowardsLeft title="Motor Acceleration/Motor parameter estimation tuning, Arc Flash, Load Flow, Ground Grid system studies…etc" number="22" image1={DOC1} image2={DE2}/>
			<Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				<img src={tools} style={{ width: "80%", marginTop: '5%', marginBottom: '3%' }} />
			</Box>
		</Box>
	);
}

export default ProductEngineering;
