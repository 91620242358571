import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import EIA1 from "../../../../assets/pages/landingPage/SubServices/EIA1.png";
import EIA2 from "../../../../assets/pages/landingPage/SubServices/EIA2.png";

function TowardsLeft({ title, number, image1, image2 }) {
  return (
    <Box
      sx={{
        position: "relative",
        marginTop: "5%",
        marginLeft: "1%",
        marginRight: "1%",
      }}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            border: "2px solid rgba(201, 201, 203, 1)",
            borderRadius: "10px 10px 10px 10px",
          }}
        >
          <Box
            sx={{
              marginRight: "200px",
              marginBottom: "7%",
              marginTop: "2%",
              marginLeft: "50px",
              height: "250px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid #202632",
                    paddingBottom: "5%",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Avenir",
                      fontWeight: 500,
                      fontSize: "17px",
                      textAlign: "left",
                      color: "#202632",
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "3%" }}>
                  <a href="mailto:enquiry@hertzandhenry.com">
                    <Box
                      sx={{
                        display: "flex",
                        marginTop: "2%",
                        position: "relative",
                        zIndex: "1000",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Avenir",
                          fontWeight: 500,
                          fontSize: "16px",
                          textAlign: "left",
                          color: "#202632",
                        }}
                      >
                        Need This Service
                      </Typography>
                      <svg
                        style={{ marginLeft: "10px" }}
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="11"
                          cy="11"
                          r="10.65"
                          stroke="#161B26"
                          stroke-width="0.7"
                        />
                        <path
                          d="M9.53516 7.33203L12.4947 10.2916C12.8852 10.6821 12.8852 11.3153 12.4947 11.7058L9.53516 14.6654"
                          stroke="#161B26"
                          stroke-linecap="round"
                        />
                      </svg>
                    </Box>
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Avenir",
                      fontWeight: 900,
                      fontSize: { xs: "40px", sm: "60px", md: "100px" },
                      textAlign: "right",
                      color: "rgba(201, 201, 203, 1)",
                      opacity: "33%",
                      marginLeft: "10px",
                    }}
                  >
                    {number}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
      <Box
        sx={{
          position: "absolute",
          top: 12,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 5,
        }}
      >
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item xs={12} sm={4.3}></Grid>
          <Grid
            item
            xs={12}
            sm={4.2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Box sx={{ marginLeft: "5%" }}>
              <img src={image1} style={{ width: "100%" }} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2.85}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Box sx={{ marginLeft: "5%" }}>
              <img src={image2} style={{ width: "100%" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default TowardsLeft;
